import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import { propEq, sortBy } from 'ramda'
import { getTeacherList, updateTeacher } from 'api/teachers'
import { dateToString, dateFromString, dateToStringV2 } from 'utils/date'
import { textFilter } from 'utils/table'
import InformationTable from '../../components/InformationTable/InformationTable'
import SearchInput from '../../elements/SearchInput/SearchInput'
import { useMessageSnackbarActions } from '../../elements/MessageContext/MessageContext'
import TeacherAvatar from '../../elements/TeacherAvatar/TeacherAvatar'
import { Button } from '@material-ui/core'
import ConfirmModal from 'shared/ConfirmModal'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { syncAgoraTeacherSchedules } from 'api/teacherSchedules'
import styles from './ListadoProfesores.module.css'

const StatusButton = ({ active, onClick }) => (
  <IconButton
    className={styles.statusButton}
    aria-label='Profesor activ@'
    onClick={onClick}
  >
    <StatusIcon active={active} />
  </IconButton>
)

const StatusIcon = ({ active }) => {
  if (active)
    return (
      <i title='Activo' className='material-icons' style={{ color: '#8cc63f' }}>
        done
      </i>
    )
  else
    return (
      <i
        title='Inactivo'
        className='material-icons'
        style={{ color: '#f0513c' }}
      >
        clear
      </i>
    )
}

const ListadoProfesores = () => {
  const { setSuccessMessage, setErrorMessage } = useMessageSnackbarActions()
  const [teachers, setTeachers] = useState([])
  const [agoraSchedule, setAgoraSchedule] = useState({
    startDate: new Date(),
    endDate: null
  })
  const [search, setSearch] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const filterSearch = subject => {
    return textFilter({
      object: subject,
      fields: ['name', 'email'],
      search: search
    })
  }
  useEffect(() => {
    getTeacherList()
      .then(sortBy(propEq('active', false)))
      .then(setTeachers)
      .catch(() => setErrorMessage('No se puede mostrar el listado'))
  }, [setErrorMessage])

  const openModal = () => {
    setShowModal(true)
  }
  const closeModal = () => {
    setShowModal(false)
  }

  const handleProfesorEligibleChange = teacher => () => {
    updateTeacher({
      teacherId: teacher._id,
      update: {
        eligible: !teacher.eligible
      }
    })
      .then(() => {
        setTeachers(currentTeachers => {
          return currentTeachers.map(currentTeacher => {
            if (teacher._id !== currentTeacher._id) return currentTeacher
            else return { ...teacher, eligible: !teacher.eligible }
          })
        })
      })
      .catch(e => setErrorMessage(e.message))
  }

  const handleStartDateChange = startDate =>
    setAgoraSchedule(prevState => ({
      ...prevState,
      startDate
    }))

  const handleEndDateChange = endDate =>
    setAgoraSchedule(prevState => ({
      ...prevState,
      endDate
    }))

  const handleSyncSchedule = () => {
    setIsLoading(true)
    syncAgoraTeacherSchedules(
      dateToStringV2(agoraSchedule.startDate),
      dateToStringV2(agoraSchedule.endDate)
    )
      .then(data => {
        data.failedIds && data.failedIds.length > 0
          ? setErrorMessage(
              `¡Atención! No se han podido sincronizar ${data.failedIds.length} horarios de profes`
            )
          : setSuccessMessage('Horarios sincronizados exitosamente.')
      })
      .catch(error => {
        setErrorMessage('Error al sincronizar horarios.')
        console.error('Error en la sincronización:', error)
      })
      .finally(() => {
        setIsLoading(false)
        closeModal()
      })
  }
  return (
    <div>
      <div className={styles.toolbar}>
        <SearchInput value={search} onChange={e => setSearch(e.target.value)} />
        <Button color='primary' variant='contained' onClick={openModal}>
          Sincronizar horarios
        </Button>
      </div>
      <InformationTable
        className={styles.table}
        size='small'
        details={[
          {
            title: '',
            key: 'avatar',
            align: 'left',
            style: { width: 45 },
            sortDisabled: true
          },
          { title: 'Nombre profesor', key: 'name', align: 'left' },
          {
            title: 'Email',
            key: 'email',
            sortFunction: (a, b) => dateFromString(a) - dateFromString(b)
          },
          { title: 'Último Login', key: 'lastLogin', sortDisabled: true },
          {
            title: 'Elegible',
            key: 'eligible',
            sortDisabled: true,
            align: 'center'
          },
          {
            title: 'Acceso',
            key: 'active',
            sortDisabled: true,
            align: 'center'
          }
        ]}
        data={teachers.filter(filterSearch).map(teacher => ({
          _id: teacher._id,
          avatar: (
            <Link to={`/staff/teachers/${teacher._id}`}>
              <TeacherAvatar teacher={teacher} useThumbnail />
            </Link>
          ),
          email: teacher.email,
          name: (
            <Link to={`/staff/teachers/${teacher._id}`}>{teacher.name}</Link>
          ),
          lastLogin: teacher.lastSuccessfullLogin
            ? dateToString(teacher.lastSuccessfullLogin)
            : '--',
          active: <StatusButton active={teacher.active} disabled />,
          eligible: (
            <StatusButton
              active={teacher.eligible}
              onClick={handleProfesorEligibleChange(teacher)}
            />
          )
        }))}
      />

      {showModal && (
        <ConfirmModal
          title='Sincronización de horarios de profes: Ágora a Classfy'
          subtitle='¡Atención! Asegúrate de seleccionar la fecha de fin adecuada.'
          onOk={handleSyncSchedule}
          onCancel={closeModal}
          isLoading={isLoading}
          okDisabled={!agoraSchedule.startDate || !agoraSchedule.endDate}
          showModal
        >
          <p>
            Se llevará a cabo la <strong>sincronización</strong> de los{' '}
            <strong>horarios de profes </strong> de <strong>Ágora</strong> con{' '}
            <strong>Classfy</strong>.
          </p>
          <div className={styles.datePickerContainer}>
            <KeyboardDatePicker
              label='Fecha de inicio'
              margin='dense'
              format='dd/MM/yyyy'
              value={agoraSchedule.startDate || null}
              onChange={handleStartDateChange}
              fullWidth
            />
            <KeyboardDatePicker
              label='Fecha de fin'
              margin='dense'
              format='dd/MM/yyyy'
              value={agoraSchedule.endDate || null}
              onChange={handleEndDateChange}
              fullWidth
            />
          </div>
        </ConfirmModal>
      )}
    </div>
  )
}

export default ListadoProfesores
