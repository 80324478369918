import { GET, POST, PUT } from 'api'
import { makeCustomQueryAndProject } from 'utils'

export const createSubscription = (data = {}) =>
  POST({
    url: 'admin/subscriptions/',
    data
  })
export const createAgoraSubscription = (data = {}) =>
  POST({
    url: 'admin/subscriptions/save-agora-rate',
    data
  })
export const editAgoraSubscription = (data = {}) =>
  PUT({
    url: 'admin/subscriptions/edit-agora-rate',
    data
  })
export const copyAgoraSubscription = (data = {}) =>
  POST({
    url: 'admin/subscriptions/copy-agora-rate',
    data
  })
export const findDailySubscriptions = () =>
  GET({
    url: 'admin/subscriptions/daily'
  })
export const findSubscriptions = query =>
  GET({
    url: makeCustomQueryAndProject({
      query,
      url: 'admin/subscriptions'
    })
  })

export const makeInvoice = (data = {}) =>
  POST({
    url: 'admin/subscriptions/make-invoice',
    data
  })
