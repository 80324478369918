import { GET } from 'api'

export const getAvailableHoursBy = (date, teacherId) =>
  GET({
    url: `admin/teacher-schedule/available-hours-by?date=${new Date(
      date
    ).toDateString()}&teacherId=${teacherId}`
  })

export const getTeacherSchedule = ({
  teacherId,
  year = new Date().getFullYear(),
  month = new Date().getMonth()
}) =>
  GET({
    url: `admin/teacher-schedule/list?teacherId=${teacherId}&month=${month}&year=${year}`
  })

export const syncAgoraTeacherSchedules = (startDate = '', endDate = '') =>
  GET({
    url: `admin/agora/sync-teacher-schedules?startDate=${startDate}&endDate=${endDate}`
  })

export const getTeacherSchedulesByRange = ({
  teacherId,
  startDate = Date.now(),
  endDate = Date.now()
}) =>
  GET({
    url: `admin/teacher-schedule/by-range?teacherId=${teacherId}&startDate=${startDate}&endDate=${endDate}`
  })
