import { AppBar, Tab, Tabs } from '@material-ui/core'
import React, { useState } from 'react'
import './FichaAsignatura.css'
import ProfesoresAsignatura from './tabs/ProfesoresAsignatura'
import DatosAsignatura from './tabs/DatosAsignatura'

const TabPanel = ({ active, children }) => {
  if (!active) return null
  return children
}

const FIchaAsignatura = () => {
  const [tab, setTab] = useState(0)

  const handleChange = (_e, newValue) => setTab(newValue)

  return (
    <div className='FIchaAsignatura'>
      <AppBar color='secondary' position='static'>
        <Tabs
          value={tab}
          onChange={handleChange}
          variant='fullWidth'
          indicatorColor='primary'
        >
          <Tab label='Detalles' />
          <Tab label='Profesores' />
        </Tabs>
      </AppBar>
      <TabPanel active={tab === 0}>
        <DatosAsignatura />
      </TabPanel>
      <TabPanel active={tab === 1}>
        <ProfesoresAsignatura />
      </TabPanel>
    </div>
  )
}

export default FIchaAsignatura
