//@ts-check
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography
} from '@material-ui/core'
import JoditEditor from 'jodit-react'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { addTicketMessage, getTicket, toggleTicketStatus } from 'api/ticketing'
import { dateTimeToString } from 'utils/date'
import { getAuthenticationDetails } from 'utils'
import LoadingButton from '../../../elements/LoadingButton/LoadingButton'
import { useMessageSnackbarActions } from '../../../elements/MessageContext/MessageContext'
import Spinner from '../../../elements/Spinner/Spinner'
import ConfirmModal from '../../../shared/ConfirmModal'

const config = {
  toolbar: false,
  readonly: true,
  showCharsCounter: false,
  showWordsCounter: false,
  showXPathInStatusbar: false,
  minHeight: null
}
const COMMON_BUTTONS =
  'bold,italic,underline,strikethrough,eraser,ul,ol,font,fontsize,lineHeight,spellcheck,cut,copy,paste,selectall,symbols,indent,outdent'

const configEdit = {
  buttons: COMMON_BUTTONS,
  buttonsMD: COMMON_BUTTONS,
  buttonsSM: COMMON_BUTTONS,
  buttonsXS: COMMON_BUTTONS,
  readonly: false, // all options from https://xdsoft.net/jodit/doc/,
  placeholder: 'Empieza a escribir...',
  toolbarButtonSize: 'small',
  showXPathInStatusbar: false,
  disablePlugins: 'powered-by-jodit',
  maxHeight: 350
}
function TicketEdit() {
  const { id: ticketId } = useParams()
  const [ticket, setTicket] = useState({})
  const [isFecthing, setIsFetching] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [message, setMessage] = useState('')
  const { setSuccessMessage, setErrorMessage } = useMessageSnackbarActions()

  const handleClick = useCallback(() => {
    setShowModal(true)
  }, [])
  const handleJoditChange = useCallback(value => {
    if (['<p><br></p>', '<p></p>'].includes(value)) return setMessage('')
    setMessage(value)
  }, [])

  const fetchData = useCallback(() => {
    setIsFetching(true)
    getTicket(ticketId)
      .then(ticket => setTicket({ ...ticket, thread: ticket.thread.reverse() }))
      .catch(e => {
        setErrorMessage(e.message)
        console.error('Error getting ticket: ', e.message)
      })
      .finally(() => setIsFetching(false))
  }, [setErrorMessage, ticketId])
  const handleSubmit = useCallback(() => {
    setIsSaving(true)
    addTicketMessage(ticketId, {
      content: message,
      createdBy: getAuthenticationDetails()?._id
    })
      .then(() => {
        fetchData()
        setMessage('')
        setShowModal(false)
        setSuccessMessage()
      })
      .catch(e => {
        setErrorMessage(e.message)
        console.error('Error adding new message: ', e.message)
      })
      .finally(() => setIsSaving(false))
  }, [message, setErrorMessage, setSuccessMessage, ticketId, fetchData])
  const handleToggleStatus = useCallback(() => {
    toggleTicketStatus(ticketId)
      .then(() => {
        fetchData()
        setSuccessMessage('Estado del ticket cambiado correctamente')
      })
      .catch(e => {
        console.error('Error updating ticket status: ', e.message)
        setErrorMessage(e.message)
      })
  }, [fetchData, setErrorMessage, setSuccessMessage, ticketId])
  useEffect(() => {
    fetchData()
  }, [fetchData])

  if (isFecthing) return <Spinner />
  return (
    <>
      <Card variant='outlined'>
        <CardHeader
          title={`Ticket ${ticket.status === 'open' ? 'abierto' : 'cerrado'}`}
          subheader={'Asunto: ' + (ticket?.subject || '-')}
        />
        <CardContent
          style={{
            margin: '0 auto',
            overflowY: 'scroll',
            height: 'calc(100vh - 245px)',
            width: '80%',
            display: 'flex',
            gap: 20,
            flexDirection: 'column'
          }}
        >
          {ticket.thread?.map((ticketMessage, i) => {
            const isStudentMessage =
              ticketMessage.createdBy === ticket.createdBy
            return (
              <div key={i}>
                <Typography
                  style={{
                    textAlign: !isStudentMessage ? 'left' : 'right'
                  }}
                  color='textSecondary'
                  display='block'
                  variant='caption'
                >
                  {`${
                    !isStudentMessage ? 'Admin -' : 'Alumno -'
                  } ${dateTimeToString(ticketMessage.createdAt)}`}
                </Typography>
                <JoditEditor
                  value={ticketMessage.content}
                  config={config}
                  tabIndex={i}
                />
              </div>
            )
          })}
        </CardContent>
        <CardActions style={{ justifyContent: 'flex-end' }}>
          <LoadingButton
            label={ticket.status !== 'closed' ? 'Cerrar Hilo' : 'Reabrir Hilo'}
            isLoading={isSaving}
            onClick={handleToggleStatus}
            color='secondary'
            startIcon={<i className='material-icons'>close</i>}
          />

          <LoadingButton
            disabled={ticket.status === 'closed' || !ticket.status}
            label='Responder'
            isLoading={isSaving}
            onClick={handleClick}
            startIcon={<i className='material-icons'>send</i>}
          />
        </CardActions>
      </Card>
      {showModal && (
        <ConfirmModal
          title='Responder'
          subtitle='Se añadirá al hilo de mensajes'
          style={{
            width: '55%',
            margin: '100px auto'
          }}
          onCancel={() => setShowModal(false)}
          onOk={handleSubmit}
          okDisabled={!message || isSaving}
          okText='Enviar'
          isLoading={isSaving}
          showModal
        >
          <JoditEditor
            value={message}
            config={configEdit}
            tabIndex={99999}
            onChange={handleJoditChange}
          />
        </ConfirmModal>
      )}
    </>
  )
}

export default TicketEdit
