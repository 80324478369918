import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Card, CardContent, CardHeader } from '@material-ui/core'
import { getSubjectDetails } from 'api/subjects'
import { useMessageSnackbarActions } from '../../../elements/MessageContext/MessageContext'
import SubjectTypeSelector from '../../../elements/SubjectTypeSelector/SubjectTypeSelector'
import SubjectLevelSelector from '../../../elements/SubjectLevelSelector/SubjectLevelSelector'
import SubjectSublevelSelector from '../../../elements/SubjectSublevelSelector/SubjectSublevelSelector'
import { SubjectLevels } from '../../../shared/constants'
import './DatosAsignatura.css'

const DatosAsignatura = () => {
  const { id } = useParams()
  const { setErrorMessage } = useMessageSnackbarActions()

  const [subject, setSubject] = useState({
    name: '',
    price: '',
    type: '',
    level: '',
    active: true
  })

  useEffect(() => {
    getSubjectDetails({ subjectId: id })
      .then(setSubject)
      .catch(() => setErrorMessage('No se puede mostrar la ficha'))
  }, [id, setErrorMessage])

  return (
    <Card className='DatosAsignatura' variant='outlined'>
      <CardHeader title='Editar datos' />
      <CardContent>
        <TextField
          margin='dense'
          label='Nombre'
          value={subject.name}
          fullWidth
        />
        <TextField
          margin='dense'
          label='Precio (hora suelta)'
          value={subject.price}
          type='number'
          fullWidth
        />
        <SubjectTypeSelector value={subject.type} fullWidth />
        <SubjectLevelSelector value={subject.level} fullWidth />
        {subject.level === SubjectLevels.IDIOMAS && (
          <SubjectSublevelSelector value={subject.sublevel || ''} fullWidth />
        )}
        {subject.level === SubjectLevels.SECUNDARIA && (
          <SubjectSublevelSelector
            value={subject.sublevel || ''}
            target='Secundaria'
            fullWidth
          />
        )}
        {subject.level === SubjectLevels.BACHILLERATO && (
          <SubjectSublevelSelector
            value={subject.sublevel || ''}
            target='Bachillerato'
            fullWidth
          />
        )}
        <FormControlLabel
          margin='dense'
          control={<Checkbox checked={subject.active} />}
          label='Activa'
        />
      </CardContent>
    </Card>
  )
}

export default DatosAsignatura
