import React from 'react'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { AccessTypeLabels } from '../../shared/constants'
import './AccessTypeSelector.css'

const options = Object.keys(AccessTypeLabels).map(option => ({
  value: option,
  label: AccessTypeLabels[option]
}))

const AccessTypeSelector = ({
  name = '',
  value,
  onChange,
  filterOptions = () => true,
  ...rest
}) => (
  <FormControl className='AccessTypeSelector' margin='normal' {...rest}>
    <InputLabel id='selector-tipos-acceso'>Tipo de acceso</InputLabel>
    <Select
      name={name}
      labelId='selector-tipos-acceso'
      value={value}
      onChange={onChange}
      input={<Input />}
    >
      {options.filter(filterOptions).map(option => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

export default AccessTypeSelector
