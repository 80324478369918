import config from 'config'

export const googleLogin = credentials => {
  return fetch(config.google.authUrl + '/api/auth/classfy-admin-login', {
    method: 'POST',
    body: JSON.stringify(credentials),
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(async response => {
    const JSONResponse = await response.json()
    if (response.ok) return JSONResponse.body
    throw new Error(JSONResponse.message || COMMON_ERROR_MESSAGE)
  })
}
