//@ts-check
import React, { useState } from 'react'
import { AppBar, Tab, Tabs } from '@material-ui/core'
import { findSubscriptions, findDailySubscriptions } from 'api/subscriptions'
import SubscriptionList from '../../shared/SubscriptionList'
const TabPanel = ({ active, children }) => {
  if (!active) return null
  return children
}

function Operations() {
  const [tab, setTab] = useState(0)
  const handleChange = (_e, newValue) => setTab(newValue)

  return (
    <>
      <AppBar color='secondary' position='static'>
        <Tabs
          value={tab}
          onChange={handleChange}
          variant='fullWidth'
          indicatorColor='primary'
        >
          <Tab label='Operaciones del día' />
          <Tab label='Histórico' />
        </Tabs>
      </AppBar>
      <TabPanel active={tab === 0}>
        <SubscriptionList fetchFn={findDailySubscriptions} showSum />
      </TabPanel>
      <TabPanel active={tab === 1}>
        <SubscriptionList
          fetchFn={findSubscriptions}
          textSum='Recaudación del mes:'
          showSum
          showDateFilter
        />
      </TabPanel>
    </>
  )
}

export default Operations
