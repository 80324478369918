import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { getAdminList } from 'api/admins'
import { dateToString, dateFromString } from 'utils/date'
import { textFilter } from 'utils/table'
import InformationTable from '../../components/InformationTable/InformationTable'
import SearchInput from '../../elements/SearchInput/SearchInput'
import styles from './ListadoAdministradores.module.css'

const StatusIcon = ({ active }) => {
  if (active)
    return (
      <i title='Activo' className='material-icons' style={{ color: '#8cc63f' }}>
        done
      </i>
    )
  else
    return (
      <i
        title='Inactivo'
        className='material-icons'
        style={{ color: '#f0513c' }}
      >
        clear
      </i>
    )
}

const ListadoAdministradores = () => {
  const [search, setSearch] = useState('')
  const [admins, setAdmins] = useState([])

  const filterSearch = admin => {
    return textFilter({
      object: admin,
      fields: ['name', 'email'],
      search: search
    })
  }

  useEffect(() => {
    getAdminList().then(setAdmins).catch(console.error)
  }, [])

  return (
    <div>
      <div className={styles.toolbar}>
        <SearchInput value={search} onChange={e => setSearch(e.target.value)} />
      </div>
      <InformationTable
        className={styles.table}
        details={[
          { title: 'Nombre admin', key: 'name', align: 'left' },
          {
            title: 'Email',
            key: 'email',
            sortFunction: (a, b) => dateFromString(a) - dateFromString(b)
          },
          { title: 'Último Login', key: 'lastLogin', sortDisabled: true },
          {
            title: 'Activo',
            key: 'active',
            sortDisabled: true,
            align: 'center'
          }
        ]}
        data={admins.filter(filterSearch).map(admin => ({
          _id: admin._id,
          email: admin.email,
          name: <Link to={`/staff/admins/${admin._id}`}>{admin.name}</Link>,
          lastLogin: admin.lastSuccessfullLogin
            ? dateToString(admin.lastSuccessfullLogin)
            : '--',
          active: <StatusIcon active={admin.active} />
        }))}
      />
    </div>
  )
}

export default ListadoAdministradores
