import { createTheme } from '@material-ui/core/styles'

export default createTheme({
  palette: {
    primary: {
      main: '#8cc63f',
      contrastText: '#fff'
    },
    secondary: {
      main: '#f89f41',
      contrastText: '#fff'
    },
    error: {
      main: '#f0513c'
    },
    warning: {
      main: '#ef5938'
    },
    info: {
      main: '#17ab7d'
    },
    success: {
      main: '#18ab50'
    }
  }
})
