import { GET, POST, PUT } from 'api'

export const getAdminList = () =>
  GET({
    url: 'admin/admins/list'
  })

export const createAdmin = (data = {}) =>
  POST({
    url: 'admin/admins/new',
    data
  })

export const getAdminDetails = ({ adminId }) =>
  GET({
    url: `admin/admins?id=${adminId}`
  })

export const updateAdmin = ({ adminId, update }) =>
  PUT({
    url: 'admin/admins',
    data: { _id: adminId, update }
  })
