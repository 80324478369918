import React from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

export const AcademicBackgroundOptions = {
  PRIMARIA: 'Primaria',
  SECUNDARIA: 'Secundaria',
  BACHILLERATO: 'Bachillerato',
  SELECTIVIDAD_EVAU: 'Selectividad/EvAu',
  PCE: 'PCE (EvAu para extranjeros)',
  UNIVERSIDAD: 'Universidad',
  ACCESO_MAYORES_25: 'Acceso mayores 25 años',
  ACCESO_MAYORES_45: 'Acceso mayores 45 años',
  ACCESO_GRADO_MEDIO: 'Acceso grado medio',
  ACCESO_GRADO_SUPERIOR: 'Acceso grado superior',
  MODULO_GRADO_MEDIO: 'Módulo grado medio',
  MODULO_GRADO_SUPERIOR: 'Módulo grado superior',
  IDIOMAS: 'Idiomas',
  OTROS: 'Otros'
}
const Options = Object.values(AcademicBackgroundOptions)

function AcademicBackgroundSelect({
  value = '',
  onChange = x => x,
  label = 'Nivel educativo',
  name,
  fullWidth,
  margin = 'dense',
  disabled = false
}) {
  return (
    <FormControl margin={margin} fullWidth={fullWidth} disabled={disabled}>
      <InputLabel>{label}</InputLabel>
      <Select name={name} value={value} onChange={onChange}>
        <MenuItem key='empty' value=''>
          Sin valor
        </MenuItem>
        {Options.map(centre => (
          <MenuItem key={centre} value={centre}>
            {centre}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default AcademicBackgroundSelect
