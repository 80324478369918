//@ts-check
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  getMonthDaysInfo,
  isSameDay,
  MONTHS,
  DAY_NAME_BY_NUMBER,
  mergeHours,
  dateToStringV2
} from 'utils/date'
import { Button, IconButton } from '@material-ui/core'
import {
  findAcademicSchedules,
  syncAgoraAcademicSchedules
} from 'api/academicSchedules'
import ConfirmModal from 'shared/ConfirmModal'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { useMessageSnackbarActions } from 'elements/MessageContext/MessageContext'
import styles from './Schedule.module.css'

const DayCell = ({ day, selectedMonth, onGetHoursByDate }) => {
  let dayClassName = 'date_identifier'
  let cellClassName = 'calendar_day'
  if (day.getMonth() === selectedMonth) dayClassName += ' active'
  if (isSameDay(new Date(), day)) {
    dayClassName += ' today'
    cellClassName += ' today'
  }
  return (
    <td className={cellClassName}>
      <div className={dayClassName}>{day.getDate()}</div>
      <div className={styles.hourList}>
        {mergeHours(onGetHoursByDate(day)).map((hour, i) => (
          <p key={i} className={styles.hourListItem}>
            {hour}
          </p>
        ))}
      </div>
    </td>
  )
}
const WeekRow = ({ week, selectedMonth, onGetHoursByDate }) => {
  return (
    <tr className='calendar_week'>
      {week.map((day, i) => (
        <DayCell
          key={i}
          day={day}
          selectedMonth={selectedMonth}
          onGetHoursByDate={onGetHoursByDate}
        />
      ))}
    </tr>
  )
}

function Schedule() {
  const [state, setState] = useState({
    selectedYear: new Date().getFullYear(),
    selectedMonth: new Date().getMonth(),
    academicSchedule: [],
    isFetching: false
  })

  const { selectedYear, selectedMonth, academicSchedule } = state
  const [agoraAcademicSchedule, setAgoraAcademicSchedule] = useState({
    startDate: new Date(),
    endDate: null
  })
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const { setSuccessMessage, setErrorMessage } = useMessageSnackbarActions()

  const weeks = useMemo(
    () => getMonthDaysInfo(selectedYear, selectedMonth),
    [selectedYear, selectedMonth]
  )

  const openModal = () => {
    setShowModal(true)
  }
  const closeModal = () => {
    setShowModal(false)
  }

  const handleStartDateChange = startDate =>
    setAgoraAcademicSchedule(prevState => ({
      ...prevState,
      startDate
    }))

  const handleEndDateChange = endDate =>
    setAgoraAcademicSchedule(prevState => ({
      ...prevState,
      endDate
    }))

  const changeMonth = useCallback(
    amount => {
      let newYear = selectedYear
      let newMonth = selectedMonth + amount
      if (newMonth === 12) {
        newMonth = 0
        newYear++
      } else if (newMonth === -1) {
        newMonth = 11
        newYear--
      }
      setState(state => ({
        ...state,
        selectedYear: newYear,
        selectedMonth: newMonth
      }))
    },
    [selectedMonth, selectedYear]
  )

  const handleGetHoursByDate = selectedDate => {
    const selectedDaySchedule = academicSchedule.find(schedule => {
      if (isSameDay(new Date(schedule.date), selectedDate)) return schedule
    })

    if (!selectedDaySchedule) {
      return []
    }

    return selectedDaySchedule.hours
  }

  const fetchData = useCallback(async () => {
    try {
      setState(prevState => ({ ...prevState, isFetching: true }))

      const academicSchedule = await findAcademicSchedules()

      setState(prevState => ({
        ...prevState,
        academicSchedule,
        isFetching: false
      }))
    } catch (error) {
      console.error('Error fetching academicSchedule: ', error)
      setState(prevState => ({ ...prevState, isFetching: false }))
    }
  }, [])

  const handleSyncAttendances = () => {
    setIsLoading(true)
    syncAgoraAcademicSchedules(
      dateToStringV2(agoraAcademicSchedule.startDate),
      dateToStringV2(agoraAcademicSchedule.endDate)
    )
      .then(data => {
        fetchData()
        data.failedIds && data.failedIds.length > 0
          ? setErrorMessage(
              `¡Atención! No se han podido sincronizar ${data.failedIds.length} asistencias`
            )
          : setSuccessMessage('Asistencias sincronizadas exitosamente.')
      })
      .catch(error => {
        setErrorMessage('Error al sincronizar asistencias.')
        console.error('Error en la sincronización:', error)
      })
      .finally(() => {
        setIsLoading(false)
        closeModal()
      })
  }

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <div>
      <div className={styles.calendarContainer}>
        <div className={styles.buttonContainer}>
          <Button color='primary' variant='contained' onClick={openModal}>
            Sincronizar
          </Button>
        </div>
        <div>
          <div>
            <IconButton onClick={() => changeMonth(-1)}>
              <i className='material-icons'>arrow_back</i>
            </IconButton>
            <h2 className='calendar_month_name'>{`${MONTHS[selectedMonth]} de ${selectedYear}`}</h2>
            <IconButton onClick={() => changeMonth(1)}>
              <i className='material-icons'>arrow_forward</i>
            </IconButton>
          </div>
          <table className={styles.calendarElement}>
            <thead>
              <tr>
                {DAY_NAME_BY_NUMBER.map(({ label, day }) => {
                  return (
                    <th key={day} className={styles.calendarElementHeader}>
                      {label}
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {weeks.map((week, i) => (
                <WeekRow
                  key={i}
                  week={week}
                  selectedMonth={selectedMonth}
                  onGetHoursByDate={handleGetHoursByDate}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {showModal && (
        <ConfirmModal
          title='Sincronización del horario online: Ágora a Classfy'
          subtitle='¡Atención! Asegúrate de seleccionar la fecha de fin adecuada.'
          onOk={handleSyncAttendances}
          onCancel={closeModal}
          okDisabled={
            !agoraAcademicSchedule.startDate || !agoraAcademicSchedule.endDate
          }
          isLoading={isLoading}
          showModal
        >
          <p>
            Se llevará a cabo la <strong>sincronización</strong> del{' '}
            <strong>horario online</strong> de <strong>Ágora</strong> con{' '}
            <strong>Classfy</strong>.
          </p>
          <div className={styles.datePickerContainer}>
            <KeyboardDatePicker
              label='Fecha de inicio'
              margin='dense'
              format='dd/MM/yyyy'
              value={agoraAcademicSchedule.startDate || null}
              onChange={handleStartDateChange}
              fullWidth
            />
            <KeyboardDatePicker
              label='Fecha de fin'
              margin='dense'
              format='dd/MM/yyyy'
              value={agoraAcademicSchedule.endDate || null}
              onChange={handleEndDateChange}
              fullWidth
            />
          </div>
        </ConfirmModal>
      )}
    </div>
  )
}

export default Schedule
