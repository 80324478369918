import { GET } from './index'

export const getEvaluationsByStudentIds = ({ studentIds = [] }) =>
  GET({
    url: 'admin/evaluations/get-by-studentIds?studentIds=' + studentIds
  })
export const getEvaluationByStudentId = studentId =>
  GET({
    url: 'admin/evaluations/by-studentId/' + studentId
  })
