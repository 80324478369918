//@ts-check
import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Chip } from '@material-ui/core'
import { findBudgets } from 'api/budgets'
import { textFilter } from 'utils/table'
import InformationTable from 'components/InformationTable/InformationTable'
import { useMessageSnackbarActions } from 'elements/MessageContext/MessageContext'
import SearchInput from 'elements/SearchInput/SearchInput'
import {
  BudgetStatus,
  BudgetStatusLabels,
  SubscriptionCodeLabels
} from 'shared/constants'
import styles from './BudgetList.module.css'

const colorByStatus = {
  [BudgetStatus.PENDING]: 'default',
  [BudgetStatus.REVIEWING]: 'secondary',
  [BudgetStatus.ACCEPTED]: 'primary'
}

function BudgetList() {
  const { setErrorMessage } = useMessageSnackbarActions()
  const [budgets, setBudgets] = useState([])
  const [search, setSearch] = useState('')

  const filterSearch = budget => {
    return textFilter({
      object: budget,
      fields: ['userName', 'phone', 'email'],
      search: search
    })
  }

  const fetchBudgets = useCallback(() => {
    findBudgets()
      .then(setBudgets)
      .catch(e => {
        console.error('Error fectching budgets: ', e)
        setErrorMessage('No se puede mostrar el listado')
      })
  }, [setErrorMessage])

  useEffect(() => {
    fetchBudgets()
  }, [fetchBudgets])
  return (
    <>
      <div className={styles.toolbar}>
        <SearchInput value={search} onChange={e => setSearch(e.target.value)} />
      </div>
      <InformationTable
        className={styles.table}
        size='small'
        details={[
          { title: 'Nombre', key: 'userName', align: 'left' },
          { title: 'Info', key: 'additionalInfo' },
          {
            title: 'Creación',
            key: 'createdAt'
          },
          { title: 'Contratación', key: 'subscriptionName' },
          { title: 'Total', key: 'totalPrice' },
          {
            title: 'Estado',
            key: 'status'
          },
          {
            title: 'Detalles',
            key: 'viewDetails'
          }
        ]}
        data={budgets.filter(filterSearch).map(budget => ({
          userName: (
            <Link to={`/students/${budget.userId}`}>{budget.userName}</Link>
          ),
          createdAt: new Date(budget.createdAt).toLocaleDateString(),
          additionalInfo: (
            <div>
              <p>{budget.phone}</p>
              <p>{budget.email}</p>
            </div>
          ),
          subscriptionName: SubscriptionCodeLabels[budget.config?.code] || '??',
          totalPrice: (budget.totalPrice || 0) + '€',
          status: (
            <Chip
              label={BudgetStatusLabels[budget.status]}
              size='small'
              color={colorByStatus[budget.status]}
            />
          ),
          viewDetails: (
            <Link to={`/budgets/${budget.id}`}>
              <i title='Ver' className='material-icons'>
                remove_red_eye
              </i>
            </Link>
          )
        }))}
      />
    </>
  )
}

export default BudgetList
