import { useCallback, useEffect, useState } from 'react'
import { assoc } from 'ramda'
import lifecycle from 'page-lifecycle'
import { getTicketCount } from 'api/ticketing'
import { getCurrentMonth } from 'utils/date'
import { TicketTypes } from 'shared/constants'

const INTERVAL_TIME = 15 * 60 * 1000
const PAGE_STATES = ['hidden', 'passive']
const specialRequests = [
  TicketTypes.APP_HELP,
  TicketTypes.BUDGET,
  TicketTypes.REGISTER
]
function useNotifications() {
  const [{ newPageState, specialRequestCount }, setState] = useState({
    newPageState: '',
    specialRequestCount: 0
  })

  const fetchData = useCallback(() => {
    getTicketCount({ ...getCurrentMonth(), types: specialRequests })
      .then(count => setState(assoc('specialRequestCount', count)))
      .catch(console.error)
  }, [])

  useEffect(() => {
    fetchData()
    let intervalId = setInterval(() => {
      fetchData()
    }, INTERVAL_TIME)

    return () => clearInterval(intervalId)
  }, [fetchData])

  useEffect(() => {
    const listener = function (event) {
      setState(assoc('newPageState', event.newState))
    }
    lifecycle.addEventListener('statechange', listener)

    return () => {
      lifecycle.removeEventListener('statechange', listener)
    }
  }, [])

  useEffect(() => {
    if (PAGE_STATES.includes(newPageState) && specialRequestCount > 0)
      document.title = `(${specialRequestCount}) Admin - Classfy`
    else document.title = 'Admin - Classfy'
    fetchData()
  }, [fetchData, newPageState, specialRequestCount])

  return { specialRequestCount }
}

export default useNotifications
