import React, { useCallback, useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Tab, Tabs } from '@material-ui/core'
import { getStudentDetails, updateStudent } from 'api/students'
import { updateBillingData } from 'api/students'
import { useMessageSnackbarActions } from '../../../elements/MessageContext/MessageContext'
import StudentForm from '../../../sections/CrearAlumno/StudentForm'
import Spinner from '../../../elements/Spinner/Spinner'
import { AccessTypes } from '../../../shared/constants'
import BillingForm from './BillingForm'
import ServiceTerms from './ServiceTerms'

const TabPanel = ({ active, children }) => {
  if (!active) return null
  return children
}
const DatosAlumno = () => {
  const { id } = useParams()
  const history = useHistory()
  const [tab, setTab] = useState(0)

  const handleChange = (_e, newValue) => setTab(newValue)

  const { setErrorMessage, setSuccessMessage } = useMessageSnackbarActions()

  const [student, setStudent] = useState({})
  const [isLoading, setLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const fetchData = useCallback(() => {
    setLoading(true)
    getStudentDetails({ studentId: id })
      .then(data => setStudent(data))
      .catch(() => setErrorMessage('No se puede mostrar la ficha'))
      .finally(() => setLoading(false))
  }, [id, setErrorMessage])
  const processSuccess = () => {
    setSuccessMessage(`Alumno ${student.name} actualizado`)
    history.push('/students')
  }

  const processError = error => {
    setErrorMessage(error.message)
    setLoading(false)
  }

  const handleSubmitPersonalData = async form => {
    // @ts-ignore
    if (
      form.accessType === AccessTypes.STUDENT &&
      (!form.dateOfBirth || isNaN(Date.parse(form.dateOfBirth)))
    )
      return setErrorMessage('Introduzca una fecha correcta')
    setLoading(true)

    updateStudent({ studentId: id, update: form })
      .then(processSuccess)
      .catch(processError)
      .finally(() => setLoading(false))
  }

  const handleSubmitBillingData = useCallback(
    form => {
      // @ts-ignore
      if (!form.dateOfBirth || isNaN(Date.parse(form.dateOfBirth)))
        return setErrorMessage('Introduzca una fecha correcta')

      setIsSaving(true)
      updateBillingData(id, form)
        .then(() => {
          setSuccessMessage('Datos de facturación actualizados correctamente')
          fetchData()
        })
        .catch(e => {
          console.error('Error updating billing data: ', e)
          setErrorMessage(e.message)
        })
        .finally(() => setIsSaving(false))
    },
    [fetchData, setErrorMessage, setSuccessMessage, id]
  )

  useEffect(() => {
    fetchData()
  }, [fetchData])
  if (isLoading || !student) return <Spinner />
  const PersonalDataComponent = (
    <StudentForm
      student={student}
      isLoading={isLoading}
      mainTitle='Editar ficha'
      onSubmit={handleSubmitPersonalData}
      isEditMode={true}
    />
  )
  if (student.accessType !== AccessTypes.STUDENT) return PersonalDataComponent
  return (
    <>
      <Tabs
        value={tab}
        onChange={handleChange}
        variant='fullWidth'
        indicatorColor='primary'
      >
        <Tab label='Datos personales' />
        <Tab label='Datos de facturación' />
        <Tab label='Términos y condiciones' />
      </Tabs>
      <TabPanel active={tab === 0}>{PersonalDataComponent}</TabPanel>
      <TabPanel active={tab === 1}>
        <BillingForm
          // @ts-ignore
          data={student?.billing ?? {}}
          isLoading={isSaving}
          onSubmit={handleSubmitBillingData}
        />
      </TabPanel>
      <TabPanel active={tab === 2}>
        <ServiceTerms {...(student?.serviceTerms ?? {})} />
      </TabPanel>
    </>
  )
}

export default DatosAlumno
