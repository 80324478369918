export const getCurrentMonth = () => {
  const now = new Date()
  return {
    month: now.getMonth() + 1,
    year: now.getFullYear()
  }
}

export const mergeHours = sortedHours => {
  const newList = []
  for (let i = 0; i < sortedHours.length; i++) {
    if (i === 0) {
      newList.push(sortedHours[i])
      continue
    }
    const previousEnds = newList[newList.length - 1].substr(-5)
    const nextBegins = sortedHours[i].substr(0, 5)
    if (previousEnds === nextBegins)
      newList[newList.length - 1] = newList[newList.length - 1]
        .slice(0, -5)
        .concat(sortedHours[i].substr(-5))
    else newList.push(sortedHours[i])
  }
  return newList
}

/**
 *
 * @param {Date} date
 * @param {string} value x.e: 11:00
 * @returns
 */
export function makeCustomDate(date, value) {
  if (!value) return
  const [hour, minutes] = value.split(':')
  return new Date(new Date(date).setHours(Number(hour), Number(minutes), 0, 0))
}

export const dateToString = dateOriginal => {
  if (dateOriginal === null || dateOriginal === undefined) return ''
  let date = new Date(dateOriginal)
  if (date) {
    let day = date.getDate() + ''
    if (day.length === 1) day = '0' + day
    let month = date.getMonth() + 1 + ''
    if (month.length === 1) month = '0' + month
    const year = date.getFullYear()
    return `${day}/${month}/${year}`
  }
  return ''
}

export const dateTimeToString = dateOriginal => {
  if (dateOriginal === null || dateOriginal === undefined) return ''
  let date = new Date(dateOriginal)
  if (date) {
    let day = date.getDate() + ''
    if (day.length === 1) day = '0' + day
    let month = date.getMonth() + 1 + ''
    if (month.length === 1) month = '0' + month
    const year = date.getFullYear()

    let hours = date.getHours() + ''
    if (hours.length === 1) hours = '0' + hours
    let minutes = date.getMinutes() + ''
    if (minutes.length === 1) minutes = '0' + minutes

    return `${day}/${month}/${year} ${hours}:${minutes}`
  }
  return ''
}

export const dateFromString = string => {
  if (string === undefined || string === null) return null
  if (string.match(/[0-9]{2}\/[0-9]{2}\/[0-9]{4}/)) {
    let data = string.split('/')
    let year = +data[2]
    let month = +data[1] - 1
    let day = +data[0]
    return new Date(year, month, day, 0, 0, 0, 0)
  } else return null
}

export const dateToStringV2 = dateOriginal => {
  if (dateOriginal === null || dateOriginal === undefined) return ''
  let date = new Date(dateOriginal)
  if (date instanceof Date && !isNaN(date)) {
    let day = date.getDate() + ''
    if (day.length === 1) day = '0' + day
    let month = date.getMonth() + 1 + ''
    if (month.length === 1) month = '0' + month
    const year = date.getFullYear()

    return `${day}-${month}-${year}`
  }
  return ''
}
export const stringToDate = date => {
  const day = ('0' + date.getDate()).slice(-2)
  const month = ('0' + (date.getMonth() + 1)).slice(-2)
  const year = date.getFullYear().toString().slice(-2)
  return `${day}/${month}/${year}`
}
export const dateTimeFromString = value => {
  if (!value) return null
  if (value.match(/[0-9]{2}\/[0-9]{2}\/[0-9]{4} [0-9]{2}:[0-9]{2}/)) {
    const [date, time] = value.split(' ')
    const dateData = date.split('/', 3)
    const year = +dateData[2]
    const month = +dateData[1] - 1
    const day = +dateData[0]
    const timeData = time.split(':', 2)
    const hour = +timeData[0]
    const minute = +timeData[1]
    return new Date(year, month, day, hour, minute, 0, 0)
  } else return null
}

export const isSameDay = (date1, date2) => {
  if (!date1 || !date2) return false
  return (
    date1.getDate() == date2.getDate() &&
    date1.getMonth() == date2.getMonth() &&
    date1.getFullYear() == date2.getFullYear()
  )
}

export const toTimeString = value => {
  if (!value) return ''
  if (typeof value === 'number') value = new Date(value)
  value = new Date(value)
  let hour = value.getHours() + ''
  if (hour.length === 1) hour = '0' + hour
  let minute = value.getMinutes() + ''
  if (minute.length === 1) minute = '0' + minute
  return `${hour}:${minute}`
}
/**
 *
 * @param {number} year
 * @param {number} month
 * @returns {Date[][]}
 */
export const getMonthDaysInfo = (year, month) => {
  let weeks = []
  const firstOfMonth = new Date(year, month, 1)
  const lastOfMonth = new Date(year, month + 1, 0)
  let day = firstOfMonth.getDay() - 1
  if (day === -1) day = 6
  let firstDayRendered = new Date(year, month, 1 - day)

  let inRange = true
  while (inRange) {
    weeks.push(
      [0, 1, 2, 3, 4, 5, 6].map(i => {
        return new Date(
          firstDayRendered.getFullYear(),
          firstDayRendered.getMonth(),
          firstDayRendered.getDate() + i
        )
      })
    )
    if (weeks[weeks.length - 1][6] >= lastOfMonth) break

    firstDayRendered = new Date(
      firstDayRendered.getFullYear(),
      firstDayRendered.getMonth(),
      firstDayRendered.getDate() + 7
    )
  }
  return weeks
}

export const MONTHS = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre'
]
export const DAY_NAME_BY_NUMBER = [
  { label: 'Lunes', day: 1 },
  { label: 'Martes', day: 2 },
  { label: 'Miércoles', day: 3 },
  { label: 'Jueves', day: 4 },
  { label: 'Viernes', day: 5 },
  { label: 'Sábado', day: 6 },
  { label: 'Domingo', day: 0 }
]
