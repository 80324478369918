//@ts-check
import React from 'react'
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core'

export const EMPTY_VALUE = ''

function DefaultSelect({
  name = '',
  value,
  title = '',
  onChange,
  options = [],
  valueKey = 'id',
  labelKey = 'name',
  helperText = '',
  showEmptyValue = true,
  multiple = false,
  ...rest
}) {
  return (
    <FormControl margin='normal' fullWidth {...rest}>
      {title && <InputLabel>{title}</InputLabel>}
      <Select
        name={name}
        value={value}
        onChange={onChange}
        input={<Input />}
        multiple={multiple}
      >
        {showEmptyValue && (
          <MenuItem key='empty' value={EMPTY_VALUE}>
            Sin valor
          </MenuItem>
        )}
        {options.map(option => (
          <MenuItem key={option[valueKey]} value={option[valueKey]}>
            {option[labelKey]}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}

export default DefaultSelect
