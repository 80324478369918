import { useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TableContainer
} from '@material-ui/core'
import { LoadingSpinner } from '../PageLoading/PageLoading'
import css from './InformationTable.module.css'

/*
 * How InformationTable works:
 * - Details contains the columns to map
 * - Data contains the rows themselves
 * - Size is the size of the table (small, medium, large)
 * - Hover is whether or not the rows have the hover experience
 *
 * - emptyText is the text to display when there is no data
 * - isFetching is whether or not the data is being fetched
 *
 * Details:
 * - title: The title of the column
 * - key: The key of the column in the data
 * - align: The alignment of the column (left, center, right)
 * - visibility: Minimum screen with needed to render the column (visible, xl, lg, md, sm)
 * - sortDisabled: Whether or not the column is sortable
 * - sortFunction: A custom sort function for the column
 * - cell: A custom class for the column header
 * - className: A custom class for the column cells
 */

const InformationTable = ({
  details = [],
  data,
  emptyText = 'No hay datos',
  size = 'medium',
  className = '',
  hover = true,
  isFetching = false
}) => {
  const [sort, setSort] = useState({ field: '', order: 'desc' })

  const changeSortOrder = field => () => {
    setSort(current => ({
      field,
      order:
        field === current.field && current.order === 'desc' ? 'asc' : 'desc'
    }))
  }

  const TableColumnTitle = ({ detail }) => {
    const { title, key } = detail
    if (detail.sortDisabled) return title
    return (
      <TableSortLabel
        active={sort.field === title}
        direction={sort.order}
        onClick={changeSortOrder(key)}
      >
        {title}
      </TableSortLabel>
    )
  }

  return (
    <TableContainer className={[css.table, className].join(' ')}>
      <Table stickyHeader size={size || 'medium'}>
        <TableHead>
          <TableRow>
            {details.map(detail => (
              <TableCell
                key={detail.title}
                className={detail.cell}
                style={detail.style || {}}
                data-visibility={detail.visibility || 'visible'}
                align={detail.align || 'center'}
              >
                <TableColumnTitle detail={detail} />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length === 0 && (
            <TableRow>
              <TableCell colSpan={details.length} align='center'>
                {isFetching ? (
                  <LoadingSpinner className={css.loading} />
                ) : (
                  emptyText
                )}
              </TableCell>
            </TableRow>
          )}
          {data
            .sort((a, b) => {
              const sortDetail = details.find(
                detail => detail.key === sort.field
              )
              if (!sortDetail) return 0
              const { sortKey, key } = sortDetail
              const activeSortKey = sortKey || key
              if (sortDetail.sortFunction) {
                const result = sortDetail.sortFunction(
                  a[activeSortKey],
                  b[activeSortKey]
                )
                return sort.order === 'desc' ? -1 * result : result
              }
              if (a[activeSortKey] < b[activeSortKey])
                return sort.order === 'desc' ? -1 : 1
              if (a[activeSortKey] > b[activeSortKey])
                return sort.order === 'desc' ? 1 : -1
              return 0
            })
            .map((row, index) => (
              <TableRow
                className={row.className || ''}
                style={row?.style}
                hover={hover}
                key={row.key || index}
              >
                {details.map(detail => (
                  <TableCell
                    key={detail.key}
                    className={detail.className || ''}
                    data-visibility={detail.visibility || 'visible'}
                    align={detail.align || 'center'}
                  >
                    {row[detail.key]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default InformationTable
