//@ts-check
import React, { useCallback, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { GoogleLogin } from '@react-oauth/google'
import { isAuthenticated, saveAuthentication } from '../../utils'
import { Card, CardContent, Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { googleLogin } from 'api/auth'
import logo from './resources/logo.png'
import styles from './Login.module.css'

function makeErrorMessage(e) {
  if (!e || !e.message) return 'Error desconocido al iniciar sesión'
  return e.message
}

const Login = () => {
  const [isError, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setLoading] = useState(false)

  const history = useHistory()

  const processSuccess = useCallback(
    data => {
      setLoading(false)
      saveAuthentication(data)
      history.push('/')
    },
    [history]
  )
  const processError = useCallback(e => {
    setLoading(false)
    setError(true)
    setErrorMessage(makeErrorMessage(e))
    setTimeout(() => setError(false), 6000)
  }, [])

  const handleGoogleLogin = useCallback(
    credentials => {
      if (isLoading) return
      setLoading(true)
      googleLogin(credentials).then(processSuccess).catch(processError)
    },
    [isLoading, processError, processSuccess]
  )

  if (isAuthenticated()) return <Redirect to='/' />

  return (
    <div className={styles.main}>
      <Card className={styles.card} raised>
        <CardContent className={styles.googleContainer}>
          <img className={styles.image} src={logo} alt='mundoestudiante' />
          <GoogleLogin
            text='signin_with'
            onSuccess={handleGoogleLogin}
            onError={processError}
          />
        </CardContent>
      </Card>
      <Snackbar
        className='Login-error'
        open={isError}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert elevation={6} variant='filled' severity='error'>
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default Login
