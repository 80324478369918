import React from 'react'
import { AppBar, Toolbar, Tooltip, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { getAuthenticationDetails } from '../../utils'
import config from 'config'
import logo from './resources/logo.png'
import './Header.css'

const noop = () => {}

const Header = ({ onLogout = noop }) => {
  return (
    <AppBar className='Header'>
      <Toolbar className='Header-toolbar'>
        <Link to='/'>
          <img className='Header-logo' src={logo} alt='mundoestudiante' />
        </Link>
        {config.environmentTag && (
          <Typography variant='h6' className='highlight-text'>
            {config.environmentTag}
          </Typography>
        )}
        <div className='Header-actions'>
          <Link
            className='Header-link'
            to='#'
            onClick={() => window.location.reload(true)}
          >
            <i className='material-icons'>cached</i>
          </Link>
          <Link className='Header-link' to='/mi-cuenta'>
            <Tooltip
              title={getAuthenticationDetails()?.name || 'Sin identificar'}
            >
              <i className='material-icons'>account_circle</i>
            </Tooltip>
          </Link>
          <Link className='Header-link' to='#' onClick={onLogout}>
            <i className='material-icons'>power_settings_new</i>
          </Link>
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default Header
