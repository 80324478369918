import React, { useEffect, useState } from 'react'
import { propEq } from 'ramda'
import { useParams, useHistory } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import { Card, CardActions, CardContent, CardHeader } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { getTeacherDetails, updateTeacher } from 'api/teachers'
import LoadingButton from '../../../elements/LoadingButton/LoadingButton'
import { useMessageSnackbarActions } from '../../../elements/MessageContext/MessageContext'
import SubjectSelector from '../../../elements/SubjectSelector/SubjectSelector'
import SubjectTypeSelector from '../../../elements/SubjectTypeSelector/SubjectTypeSelector'
import TeacherAvatar from 'elements/TeacherAvatar/TeacherAvatar'
import styles from './DatosProfesor.module.css'

const CLASSROOM_LIMIT = 12

const DatosProfesor = () => {
  const { id } = useParams()
  const history = useHistory()
  const { setErrorMessage, setSuccessMessage } = useMessageSnackbarActions()

  const [teacher, setTeacher] = useState({
    name: '',
    email: '',
    subjects: [],
    active: true,
    eligible: true,
    type: '',
    classroomLimit: CLASSROOM_LIMIT
  })
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    getTeacherDetails({ teacherId: id })
      .then(setTeacher)
      .catch(() => setErrorMessage('No se puede mostrar la ficha'))
  }, [id, setErrorMessage])

  const processSuccess = () => {
    setSuccessMessage(`Profesor ${teacher.name} actualizado`)
    history.push('/staff/teachers')
  }

  const processError = error => {
    setErrorMessage(error.message)
    setLoading(false)
  }

  const isDisabled = () => {
    return Object.keys(teacher)
      .map(key => teacher[key] === '')
      .includes(true)
  }

  const handleSubmit = async event => {
    event.preventDefault()
    setLoading(true)

    const teacherUpdate = {
      name: teacher.name,
      email: teacher.email,
      active: teacher.active,
      eligible: teacher.eligible,
      subjects: teacher.subjects,
      type: teacher.type,
      classroomLimit: teacher.classroomLimit
    }

    updateTeacher({ teacherId: id, update: teacherUpdate })
      .then(processSuccess)
      .catch(processError)
      .finally(() => setLoading(false))
  }

  const handleTextFieldChange = key => e => {
    const value = e.target.value
    setTeacher({ ...teacher, [key]: value })
  }
  const handleCheckboxChange = (key, value) => {
    setTeacher({ ...teacher, [key]: value })
  }

  const handleTypeChange = e => {
    setTeacher(state => ({ ...state, type: e.target.value, subjects: [] }))
  }

  const handleManageSubjectByType = subject =>
    propEq('type', teacher.type)(subject)

  return (
    <Card className='bordered_shadow card_wrapper' variant='outlined'>
      <CardHeader title='Editar ficha' />
      <CardContent>
        <div className={styles.cardContentTop}>
          <TeacherAvatar teacher={teacher} />
          <div className={styles.basicInfo}>
            <TextField
              margin='dense'
              label='Nombre'
              value={teacher.name}
              disabled
              fullWidth
            />
            <TextField
              margin='dense'
              label='Correo electrónico'
              value={teacher.email}
              disabled
              fullWidth
            />
          </div>
        </div>
        <SubjectTypeSelector
          value={teacher.type || ''}
          onChange={handleTypeChange}
          fullWidth
        />
        <SubjectSelector
          fullWidth
          label='Asignaturas habilitadas'
          value={teacher.subjects || []}
          onChange={handleTextFieldChange('subjects')}
          filter={handleManageSubjectByType}
          multiple
        />
        <TextField
          margin='dense'
          label='Límite de alumnos por clase'
          value={teacher.classroomLimit || CLASSROOM_LIMIT}
          type='number'
          onChange={handleTextFieldChange('classroomLimit')}
          fullWidth
        />
        <FormControlLabel
          margin='dense'
          control={<Checkbox checked={teacher.active} disabled />}
          label='Activo'
        />
        <FormControlLabel
          margin='dense'
          control={
            <Checkbox
              checked={teacher.eligible}
              onChange={() =>
                handleCheckboxChange('eligible', !teacher.eligible)
              }
            />
          }
          label='Elegible para agendar pruebas'
        />
      </CardContent>
      <CardActions className='card_wrapper_action'>
        <LoadingButton
          type='submit'
          disabled={isDisabled()}
          label='Editar'
          isLoading={isLoading}
          onClick={handleSubmit}
          startIcon={<i className='material-icons'>save</i>}
        />
      </CardActions>
    </Card>
  )
}

export default DatosProfesor
