import React, { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { assoc, isEmpty } from 'ramda'
import { findSubscriptionsByStudent } from 'api/students'
import {
  createAgoraSubscription,
  copyAgoraSubscription,
  editAgoraSubscription
} from 'api/subscriptions'
import { dateTimeToString } from 'utils/date'
import config from 'config/index'
import SubscriptionList from '../../../shared/SubscriptionList'
import { useMessageSnackbarActions } from '../../../elements/MessageContext/MessageContext'
import LoadingButton from '../../../elements/LoadingButton/LoadingButton'
import SubjectSelector from '../../../elements/SubjectSelector/SubjectSelector'
import ConfirmModal from '../../../shared/ConfirmModal'
import styles from './Historic.module.css'

const initialFormState = {
  startDate: new Date(),
  selectedSubjectIds: []
}

function Historic() {
  const [showModal, setShowModal] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [selectedRateToCopy, setSelectedRateToCopy] = useState(null)
  const [selectedRateToEdit, setSelectedRateToEdit] = useState(null)
  const [executionResults, setExecutionResults] = useState(null)
  const [form, setForm] = useState(initialFormState)
  const { id: studentId } = useParams()
  const { setSuccessMessage, setErrorMessage } = useMessageSnackbarActions()

  const handleCreateAgoraRate = useCallback(() => {
    setIsSaving(true)

    createAgoraSubscription({
      ...form,
      studentId
    })
      .then(() => {
        setSuccessMessage('Tarifa añadida correctamente')
        setShowModal(false)
        setForm(initialFormState)
      })
      .catch(e => {
        console.error('Error saving agora rate:', e)
        setErrorMessage(e.message)
      })
      .finally(() => setIsSaving(false))
  }, [form, setErrorMessage, setSuccessMessage, studentId])

  const handleCopyAgoraSubscription = useCallback(() => {
    setIsSaving(true)
    copyAgoraSubscription({
      ...form,
      subscriptionId: selectedRateToCopy.id
    })
      .then(data => {
        setSuccessMessage('Tarifa añadida correctamente')
        setSelectedRateToCopy(null)
        setForm(initialFormState)
        setExecutionResults(data)
      })
      .catch(e => {
        console.error('Error copying agora rate:', e)
        setErrorMessage(e.message)
      })
      .finally(() => setIsSaving(false))
  }, [form, selectedRateToCopy, setErrorMessage, setSuccessMessage])

  const handleEditAgoraSubscription = useCallback(() => {
    setIsSaving(true)
    editAgoraSubscription({
      selectedSubjectIds: form.selectedSubjectIds,
      subscriptionId: selectedRateToEdit.id
    })
      .then(() => {
        setSuccessMessage('Tarifa editada correctamente')
        setSelectedRateToEdit(null)
        setForm(initialFormState)
      })
      .catch(e => {
        console.error('Error updating agora rate:', e)
        setErrorMessage(e.message)
      })
      .finally(() => setIsSaving(false))
  }, [form, selectedRateToEdit, setErrorMessage, setSuccessMessage])

  const handleFetchSubscriptions = () => findSubscriptionsByStudent(studentId)

  const handleSelectRateToCopy = subscription => {
    setSelectedRateToCopy(subscription)
    setForm({
      startDate: null,
      selectedSubjectIds: subscription.selectedSubjectIds
    })
  }

  const handleSelectRateToEdit = subscription => {
    setSelectedRateToEdit(subscription)
    setForm({
      startDate: null,
      selectedSubjectIds: subscription.selectedSubjectIds
    })
  }

  const handleCloseAddModal = () => {
    setShowModal(false)
    setForm(initialFormState)
  }
  const handleCloseCopyModal = () => {
    setSelectedRateToCopy(null)
    setForm(initialFormState)
  }
  const handleCloseEditModal = () => {
    setSelectedRateToEdit(null)
    setForm(initialFormState)
  }

  return (
    <>
      <SubscriptionList
        fetchFn={handleFetchSubscriptions}
        otherOptions={
          config.isDev && (
            <LoadingButton
              label='Añadir tarifa ágora'
              color='default'
              isLoading={isSaving}
              onClick={() => setShowModal(true)}
            />
          )
        }
        actionsColumn={
          config.isDev
            ? ({ subscription }) => (
                <div className={styles.actionsColumn}>
                  <i
                    className={[
                      'material-icons',
                      styles.actionsColumnIcon
                    ].join(' ')}
                    onClick={() => handleSelectRateToEdit(subscription)}
                  >
                    edit
                  </i>
                  <i
                    className={[
                      'material-icons',
                      styles.actionsColumnIcon
                    ].join(' ')}
                    onClick={() => handleSelectRateToCopy(subscription)}
                  >
                    content_copy
                  </i>
                </div>
              )
            : null
        }
      />
      {showModal && (
        <ConfirmModal
          title='Añadir tarifa Ágora'
          subtitle='Selecciona una fecha de inicio'
          onOk={handleCreateAgoraRate}
          onCancel={handleCloseAddModal}
          isLoading={isSaving}
          okDisabled={!form.startDate || form.selectedSubjectIds.length === 0}
          showModal
        >
          <KeyboardDatePicker
            label='Fecha de inicio'
            margin='dense'
            disableToolbar
            format='dd/MM/yyyy'
            value={form.startDate}
            onChange={date => setForm(assoc('startDate', date))}
            fullWidth
            autoFocus
            disablePast
          />
          <SubjectSelector
            label='Asignaturas habilitadas'
            value={form.selectedSubjectIds}
            onChange={e => setForm(assoc('selectedSubjectIds', e.target.value))}
            multiple
            fullWidth
          />
        </ConfirmModal>
      )}
      {!!selectedRateToCopy && (
        <ConfirmModal
          title={
            'Copiar tarifa Ágora - ' +
            dateTimeToString(selectedRateToCopy.startDate)
          }
          subtitle='Se intentará copiar la tarifa con sus asistencias'
          onOk={handleCopyAgoraSubscription}
          onCancel={handleCloseCopyModal}
          isLoading={isSaving}
          okDisabled={!form.startDate || form.selectedSubjectIds.length === 0}
          showModal
        >
          <KeyboardDatePicker
            label='Fecha de inicio'
            margin='dense'
            disableToolbar
            format='dd/MM/yyyy'
            value={form.startDate}
            // @ts-ignore
            onChange={date => setForm(assoc('startDate', date))}
            fullWidth
            autoFocus
            disablePast
          />
          <SubjectSelector
            label='Asignaturas habilitadas'
            value={form.selectedSubjectIds}
            onChange={e => setForm(assoc('selectedSubjectIds', e.target.value))}
            multiple
            fullWidth
          />
        </ConfirmModal>
      )}
      {!!selectedRateToEdit && (
        <ConfirmModal
          title={
            'Editar tarifa Ágora - ' +
            dateTimeToString(selectedRateToEdit.startDate)
          }
          subtitle='Preferiblemente añadir asignaturas. Si se quiere quitar una asignatura se comprobará si no hay asistencias generadas'
          onOk={handleEditAgoraSubscription}
          onCancel={handleCloseEditModal}
          isLoading={isSaving}
          okDisabled={form.selectedSubjectIds.length === 0}
          showModal
        >
          <SubjectSelector
            label='Asignaturas habilitadas'
            value={form.selectedSubjectIds}
            onChange={e => setForm(assoc('selectedSubjectIds', e.target.value))}
            multiple
            fullWidth
          />
        </ConfirmModal>
      )}
      {executionResults && !isEmpty(executionResults) && (
        <ConfirmModal
          title='Resultado ejecución'
          subtitle=''
          onOk={() => setExecutionResults({})}
          onCancel={() => setExecutionResults({})}
          showModal
        >
          <div
            style={{
              height: 'calc(100vh - 450px)'
            }}
          >
            {executionResults.failed.map((f, i) => {
              const { data, day, userMessage } = f
              return (
                <p key={i}>
                  {data.classStart
                    ? `${day} ${new Date(
                        data.classStart
                      ).toLocaleDateString()}: ${userMessage}`
                    : `${day}: ${userMessage}`}
                </p>
              )
            })}
            <p>
              {executionResults.success.length > 0 &&
                `Se han podido guardar ${executionResults.success.length} asistencias`}
            </p>
            {executionResults.failed.length === 0 &&
              executionResults.success.length === 0 &&
              'No se han encontrado fechas para el rango definido'}
          </div>
        </ConfirmModal>
      )}
    </>
  )
}

export default Historic
