//@ts-check
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button } from '@material-ui/core'
import { sum, prop, sortBy, hasPath } from 'ramda'
import { Link } from 'react-router-dom'
import { dateTimeToString, dateToString } from 'utils/date'
import { textFilter } from 'utils/table'
import InformationTable from '../components/InformationTable/InformationTable'
import { useMessageSnackbarActions } from '../elements/MessageContext/MessageContext'
import SearchInput from '../elements/SearchInput/SearchInput'
import MonthSelector from '../elements/MonthSelector/MonthSelector'
import YearSelector from '../elements/YearSelector/YearSelector'
import StatusIcon from './StatusIcon'
import InvoiceButton from './Invoice'
import styles from './SubscriptionList.module.css'

const VoidComponent = <></>

function SubscriptionList({
  fetchFn,
  showSum = false,
  textSum = 'Recaudación del día:',
  showDateFilter = false,
  actionsColumn = null,
  otherOptions = VoidComponent
}) {
  const { setErrorMessage } = useMessageSnackbarActions()
  const [subscriptions, setSubscriptions] = useState([])
  const [search, setSearch] = useState('')
  const [date, setDate] = useState(
    showDateFilter
      ? {
          month: new Date().getMonth() + 1,
          year: new Date().getFullYear()
        }
      : {}
  )

  const fetchData = useCallback(
    date => {
      fetchFn(date)
        .then(sortBy(list => !!hasPath(['invoiceUrl'], list)))
        .then(data => {
          setSubscriptions(data)
          return data
        })
        .catch(e => {
          console.error('Error fetching subscriptions: ', e)
          setErrorMessage('No se puede mostrar el listado')
        })
    },
    [fetchFn, setErrorMessage]
  )

  const handleDateChange = key => e => {
    const value = e.target.value
    setDate(currentDate => ({
      ...currentDate,
      [key]: value
    }))
  }

  const filterSearch = subscription => {
    return textFilter({
      object: subscription,
      fields: ['studentName'],
      search: search
    })
  }

  useEffect(() => {
    fetchData(date)
  }, [fetchData, date])

  const details = [
    {
      title: 'Alumno',
      key: 'studentName',
      align: 'left'
    },
    { title: 'Importe', key: 'paymentAmount', align: 'left' },
    { title: 'Tipo ', key: 'subscriptionName' },
    { title: 'Nota ', key: 'description' },
    { title: 'Fecha de inicio ', key: 'startDate' },
    { title: 'Fecha de pago ', key: 'createdAt' },
    { title: 'Incluye matrícula', key: 'includesEnrollment' },
    { title: 'Ver presupuesto', key: 'budgetDetails' },
    { title: 'Factura', key: 'invoice' }
  ]

  const targetDetails = actionsColumn
    ? details.concat({ title: 'Opciones', key: 'actions' })
    : details

  const ActionsColumn = actionsColumn
  return (
    <>
      <div className={styles.toolbar}>
        <SearchInput value={search} onChange={e => setSearch(e.target.value)} />
        {showDateFilter && (
          <div>
            <MonthSelector
              value={date.month}
              onChange={handleDateChange('month')}
            />
            {'  '}
            <YearSelector
              value={date.year}
              onChange={handleDateChange('year')}
            />
          </div>
        )}
        {showSum && (
          <Button color='primary' variant='text'>
            {textSum}{' '}
            {sum(
              // @ts-ignore
              subscriptions.map(prop('paymentAmount'))
            )}
            €
          </Button>
        )}
        {otherOptions}
      </div>
      <InformationTable
        details={targetDetails}
        data={subscriptions.filter(filterSearch).map(subscription => ({
          studentName: (
            <Link to={`/students/${subscription.studentId}`}>
              {subscription.studentName}
            </Link>
          ),
          paymentAmount: subscription.paymentAmount + '€',
          subscriptionName: subscription.subscriptionName,
          description: subscription.description,
          startDate: dateToString(subscription.startDate),
          createdAt: dateTimeToString(subscription.createdAt),
          includesEnrollment: (
            <StatusIcon
              active={subscription.includesEnrollment}
              positiveText='Sí'
              negativeText='No'
            />
          ),
          budgetDetails: (
            <Link to={`/budgets/${subscription.budgetId}`}>
              <i title='Ver' className='material-icons'>
                remove_red_eye
              </i>
            </Link>
          ),
          invoice: (
            <InvoiceButton
              subscription={subscription}
              onSuccess={fetchData}
              onError={setErrorMessage}
            />
          ),
          actions: ActionsColumn && (
            <ActionsColumn subscription={subscription} />
          ),
          style: !!subscription.invoiceUrl
            ? undefined
            : { background: 'rgb(248, 159, 65,.3)' }
        }))}
      />
    </>
  )
}

export default SubscriptionList
