//@ts-check
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Fab,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core'
import { assoc } from 'ramda'
import { getEvaluationByStudentId } from 'api/evaluations'
import { createAgoraProposal, findProposalsByEvaluationId } from 'api/budgets'
import { useMessageSnackbarActions } from 'elements/MessageContext/MessageContext'
import Spinner from 'elements/Spinner/Spinner'
import YearSelector from 'elements/YearSelector/YearSelector'
import MonthSelector from 'elements/MonthSelector/MonthSelector'
import ConfirmModal from 'shared/ConfirmModal'
import {
  BudgetStatus,
  BudgetStatusLabels,
  SubscriptionCodeLabels
} from 'shared/constants'
import { dateToString, getCurrentMonth } from 'utils/date'
import styles from './Evaluations.module.css'

function makeSubjectLabel({ subjectName, subjectLevel, subjectSublevel }) {
  let label = subjectName
  if (subjectLevel) label += ' de ' + subjectLevel
  if (subjectSublevel) label += '(' + subjectSublevel + ')'

  return label
}

const colorByStatus = {
  [BudgetStatus.PENDING]: 'default',
  [BudgetStatus.REVIEWING]: 'secondary',
  [BudgetStatus.ACCEPTED]: 'primary'
}

const initialState = {
  isFetching: false,
  evaluation: {}
}
function Evaluations() {
  const { id: studentId } = useParams()

  const [{ isFetching, evaluation }, setState] = useState(initialState)
  const [proposals, setProposals] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [date, setDate] = useState(getCurrentMonth())
  const [isSaving, setIsSaving] = useState(false)
  const { setErrorMessage, setWarningMessage, setSuccessMessage } =
    useMessageSnackbarActions()

  const openModal = () => {
    setShowModal(true)
  }
  const closeModal = () => {
    setShowModal(false)
  }

  const handleDateChange = key => e => {
    const value = e.target.value
    setDate(currentDate => ({
      ...currentDate,
      [key]: value
    }))
  }

  const fetchProposalDTOs = useCallback(() => {
    if (!evaluation.id) return
    findProposalsByEvaluationId(evaluation.id)
      .then(setProposals)
      .catch(() => null)
  }, [evaluation.id])

  const handleSubmit = useCallback(() => {
    setIsSaving(true)
    createAgoraProposal({
      studentId,
      month: date.month - 1,
      year: date.year
    })
      .then(res => {
        if (res.needsEspecialTicket) {
          setWarningMessage(
            'No se ha podido generar la propuesta. Revisar tickets especiales'
          )
          closeModal()
          return
        }
        fetchProposalDTOs()
        setSuccessMessage('Propuesta generada correctamente')
        closeModal()
      })
      .catch(error => {
        console.error('Error al enviar datos:', error)
        setErrorMessage('Error al enviar datos')
      })
      .finally(() => {
        setIsSaving(false)
      })
  }, [
    date,
    studentId,
    setErrorMessage,
    setWarningMessage,
    setSuccessMessage,
    fetchProposalDTOs
  ])

  useEffect(() => {
    setState(assoc('isFetching', true))
    getEvaluationByStudentId(studentId)
      .then(data => setState(assoc('evaluation', data)))
      .catch(e => setErrorMessage(e.message))
      .finally(() => setState(assoc('isFetching', false)))
  }, [studentId, setErrorMessage])

  useEffect(() => {
    fetchProposalDTOs()
  }, [fetchProposalDTOs])

  const disabledFab =
    proposals &&
    !!proposals.length &&
    proposals.some(p =>
      [BudgetStatus.ACCEPTED, BudgetStatus.REVIEWING].includes(p.status)
    )
  if (isFetching) return <Spinner />
  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <Card variant='outlined'>
          <CardHeader
            title={
              evaluation.studentName ? (
                <Typography variant='h5' color='primary'>
                  {evaluation.studentName}
                </Typography>
              ) : (
                'Sin evaluaciones'
              )
            }
          />
          <CardContent>
            <List>
              {evaluation.teacherEvaluations?.map(
                (
                  {
                    teacherName,
                    subjectName,
                    subjectLevel,
                    subjectSublevel,
                    level,
                    service
                  },
                  i
                ) => {
                  return (
                    <div key={i}>
                      <ListItem alignItems='center' className={styles.grid}>
                        <ListItemText
                          primary={
                            <Typography variant='subtitle1' color='textPrimary'>
                              {teacherName || 'Pofesor desconocido'}
                            </Typography>
                          }
                          secondary={
                            <Typography
                              variant='subtitle2'
                              color='textSecondary'
                            >
                              {makeSubjectLabel({
                                subjectName,
                                subjectLevel,
                                subjectSublevel
                              })}
                            </Typography>
                          }
                        />
                        <ListItemText
                          secondary={
                            <span className={styles.strong}>
                              {SubscriptionCodeLabels[service]}
                            </span>
                          }
                        />

                        <ListItemText
                          secondary={
                            <>
                              <span className={styles.strong}>{level}</span>
                              /10
                            </>
                          }
                        />
                      </ListItem>
                      {evaluation.teacherEvaluations.length !== i + 1 && (
                        <Divider variant='fullWidth' component='li' />
                      )}
                    </div>
                  )
                }
              )}
            </List>
          </CardContent>
        </Card>
        <br />
        {proposals.length > 0 && (
          <>
            <Typography variant='h6' color='primary'>
              Resumen propuestas asociadas
            </Typography>
            <div className={styles.proposalList}>
              {proposals.map(proposal => {
                const { id, config, status, createdAt } = proposal
                return (
                  <div key={id} className={styles.proposalRow}>
                    -
                    <Link to={'/budgets/' + id}>
                      <span className={[styles.strong, styles.link].join(' ')}>
                        {dateToString(createdAt)} -{' '}
                        {SubscriptionCodeLabels[config?.code]}:
                      </span>{' '}
                    </Link>
                    <Chip
                      label={BudgetStatusLabels[status]}
                      size='small'
                      color={colorByStatus[status]}
                    />
                  </div>
                )
              })}
            </div>
          </>
        )}
      </div>
      {evaluation.teacherEvaluations && (
        <div className={styles.floatingButtonContainer}>
          <Fab color='primary' onClick={openModal} disabled={disabledFab}>
            <i className='material-icons'>add</i>
          </Fab>
        </div>
      )}
      {showModal && (
        <ConfirmModal
          title='Generar propuesta de Ágora'
          subtitle='Se intentará generar una propuesta automática que se ajuste a las necesidades y evaluaciones del alumno.'
          onCancel={closeModal}
          onOk={handleSubmit}
          showModal={showModal}
          isLoading={isSaving}
        >
          <div className={styles.selectorContainer}>
            <MonthSelector
              value={date.month}
              onChange={handleDateChange('month')}
            />
            <YearSelector
              value={date.year}
              onChange={handleDateChange('year')}
            />
          </div>
        </ConfirmModal>
      )}
    </div>
  )
}

export default Evaluations
