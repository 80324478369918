// @ts-check
import { useCallback, useEffect, useState } from 'react'
import useDebounce from './useDebounce'

export default function useFastInput({
  name = 'fast',
  value,
  useDebounced = false,
  onChange
}) {
  const [newInputValue, setNewInputValue] = useState(value)
  const debouncedValue = useDebounce(newInputValue)
  useEffect(() => {
    if (useDebounced) {
      debouncedValue !== value &&
        onChange({ target: { name, value: debouncedValue } })
    } else onChange({ target: { name, value: newInputValue } })
  }, [name, value, debouncedValue, onChange, newInputValue, useDebounced])

  const handleFastChange = useCallback(e => {
    setNewInputValue(e.target.value)
  }, [])
  return {
    newInputValue,
    debouncedValue,
    handleFastChange
  }
}
