import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { QueryClientProvider, QueryClient } from 'react-query'
import Login from './views/Login/Login'
import Layout from './layout/Layout/Layout'
import './shared/common.css'

const queryClient = new QueryClient()

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Switch>
        <Route exact path='/login' render={() => <Login />} />
        <Route path='/' render={() => <Layout />} />
      </Switch>
    </QueryClientProvider>
  )
}

export default App
