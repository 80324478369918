import React from 'react'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { SubjectSublevels } from '../../shared/constants'
import './SubjectSublevelSelector.css'

const SubjectSublevelSelector = ({
  value,
  target = 'Idiomas',
  onChange,
  ...rest
}) => (
  <FormControl className='SubjectSublevelSelector' margin='normal' {...rest}>
    <InputLabel>Subnivel</InputLabel>
    <Select value={value} onChange={onChange} input={<Input />}>
      {Object.values(SubjectSublevels[target]).map(sublevel => (
        <MenuItem key={sublevel} value={sublevel}>
          {sublevel}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

export default SubjectSublevelSelector
