//@ts-check
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { TextField } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { isEmpty, sortBy, prop, omit } from 'ramda'
import { getTeacherListQuery } from 'api/teachers'
import { getAvailableHoursBy } from 'api/teacherSchedules'
import { findSubjects } from 'api/subjects'
import { toTimeString, makeCustomDate } from 'utils/date'
import useFormManager from '../hooks/common/useFormManager'
import {
  AttendanceStatusLabels,
  AttendanceStatus,
  SubjectLevels,
  AttendanceTypes
} from './constants'
import DefaultSelect from './DefaultSelect'

const REQUIREDS = ['subjectId', 'teacherId', 'date', 'startTime', 'endTime']

const makeInitialForm = (isCreateMode = true, attendance = {}) => {
  return {
    ...attendance,
    type: isCreateMode ? AttendanceTypes.TEST : attendance.type,
    status: isCreateMode ? AttendanceStatus.PLANNED : attendance.status,
    date: attendance.classStart ? new Date(attendance.classStart) : null,
    startTime: attendance.classStart
      ? toTimeString(attendance.classStart)
      : null,
    endTime: attendance.classEnd ? toTimeString(attendance.classEnd) : null
  }
}
function TestAttendanceForm({ onSave, attendance = {} }) {
  // @ts-ignore
  const isCreateMode = !attendance?.id
  const {
    form,
    handleFormChange,
    handleDateChange,
    clearForm,
    getErrorPropsByRequiredField,
    setForm
  } = useFormManager(REQUIREDS, makeInitialForm(isCreateMode, attendance))
  const [state, setState] = useState({
    subscriptions: [],
    subjects: [],
    teachers: [],
    availableTeacherHours: [],
    showTooltip: false
  })
  const handleSave = useCallback(() => {
    setForm(form => {
      onSave({
        ...attendance,
        ...omit(['date', 'startTime', 'endTime'], form),
        classStart: makeCustomDate(form.date, form.startTime),
        classEnd: makeCustomDate(form.date, form.endTime),
        type: AttendanceTypes.TEST
      })
      return { ...form }
    })
  }, [attendance, onSave, setForm])

  const handleSubjectChange = useCallback(
    e => {
      clearForm()
      isCreateMode &&
        handleFormChange({
          target: { name: 'status', value: AttendanceStatus.PLANNED }
        })
      handleFormChange(e)
      handleSave()
    },
    [clearForm, handleFormChange, isCreateMode, handleSave]
  )
  const handleFormTeacherChange = useCallback(
    e => {
      handleFormChange(e)
      handleFormChange({ target: { name: 'startTime', value: null } })
      handleFormChange({ target: { name: 'endTime', value: null } })
      handleSave()
    },
    [handleFormChange, handleSave]
  )
  const handleFormDateChange = useCallback(
    value => {
      handleDateChange('date', value)
      handleFormChange({ target: { name: 'startTime', value: null } })
      handleFormChange({ target: { name: 'endTime', value: null } })
      handleSave()
    },
    [handleDateChange, handleFormChange, handleSave]
  )
  const commonHandleChange = useCallback(
    e => {
      handleFormChange(e)
      handleSave()
    },
    [handleFormChange, handleSave]
  )

  const availableHours = useMemo(() => {
    const start = []
    const end = []
    if (!isEmpty(state.availableTeacherHours))
      state.availableTeacherHours?.forEach((rangeHour = '') => {
        const [left, right] = rangeHour?.split('-')
        start.push({ id: left, name: left })
        end.push({ id: right, name: right })
      })
    return { start: sortBy(prop('id'), start), end: sortBy(prop('id'), end) }
  }, [state.availableTeacherHours])
  useEffect(() => {
    findSubjects()
      .then(subjects => {
        setState(state => ({
          ...state,
          subjects: sortBy(prop('name'), subjects).map(s => {
            s.customLabel =
              s.level === SubjectLevels.IDIOMAS && s.sublevel
                ? `${s.name} (${s.sublevel})`
                : `${s.name} (${s.level})`
            return s
          })
        }))
      })
      .catch(e =>
        console.error('Error fetching subjects by selected subscription: ', e)
      )
  }, [])
  useEffect(() => {
    if (form.subjectId) {
      getTeacherListQuery({ subjectId: form.subjectId })
        .then(teachers => {
          setState(state => ({ ...state, teachers }))
        })
        .catch(e => console.error('Error getting teachers by subjectId: ', e))
    }
  }, [form.subjectId, handleFormChange, handleFormDateChange])
  useEffect(() => {
    if (form.teacherId && form.date && !isNaN(Date.parse(form.date)))
      getAvailableHoursBy(form.date, form.teacherId)
        .then(data => {
          setState(state => ({ ...state, ...data }))
        })
        .catch(e =>
          console.error(
            'Error getting available hours by date and teacher: ',
            e
          )
        )
  }, [form.teacherId, form.date, handleFormChange])
  const isCanceled = attendance.status === AttendanceStatus.CANCELED_BY_APP
  return (
    <div>
      <div>
        <DefaultSelect
          name='subjectId'
          margin='dense'
          value={form.subjectId || ''}
          title='Asignatura'
          options={state.subjects}
          labelKey='customLabel'
          onChange={handleSubjectChange}
          {...getErrorPropsByRequiredField('subjectId')}
        />
        <DefaultSelect
          name='teacherId'
          margin='dense'
          value={form.teacherId || ''}
          title='Profesor'
          options={state.teachers}
          onChange={handleFormTeacherChange}
          {...getErrorPropsByRequiredField('teacherId')}
        />
      </div>
      <div style={{ marginTop: 10 }}>
        <KeyboardDatePicker
          label='Fecha'
          margin='none'
          format='dd/MM/yyyy'
          value={form.date || null}
          onChange={handleFormDateChange}
          disableToolbar
          clearable
          fullWidth
          {...getErrorPropsByRequiredField('date')}
        />
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: 20
          }}
        >
          <DefaultSelect
            name='startTime'
            title='Hora inicio'
            options={
              isCanceled
                ? [{ id: form.startTime, name: form.startTime }]
                : availableHours.start
            }
            disabled={isCanceled}
            value={form.startTime || ''}
            onChange={commonHandleChange}
            {...getErrorPropsByRequiredField('startTime')}
          />
          <DefaultSelect
            name='endTime'
            title='Hora fin'
            options={
              isCanceled
                ? [{ id: form.endTime, name: form.endTime }]
                : availableHours.end
            }
            disabled={isCanceled}
            value={form.endTime || ''}
            onChange={commonHandleChange}
            {...getErrorPropsByRequiredField('endTime')}
          />
        </div>
        <div>
          <DefaultSelect
            name='status'
            margin='dense'
            value={form.status || ''}
            disabled={!!isCreateMode || isCanceled}
            title='Estado'
            options={Object.keys(
              isCanceled
                ? AttendanceStatusLabels
                : omit(
                    [AttendanceStatus.CANCELED_BY_APP],
                    AttendanceStatusLabels
                  )
            ).reduce((acc, key) => {
              acc.push({ id: key, name: AttendanceStatusLabels[key] })
              return acc
            }, [])}
            onChange={commonHandleChange}
            {...getErrorPropsByRequiredField('status')}
          />
          <TextField
            name='description'
            margin='dense'
            value={form.description || ''}
            label='Observaciones asistencia'
            onChange={commonHandleChange}
            disabled={isCanceled}
            fullWidth
          />
        </div>
      </div>
    </div>
  )
}

export default memo(TestAttendanceForm)
