import { GET, POST } from 'api'

export const getLiveSockets = () =>
  GET({
    url: 'admin/live/sockets'
  })

export const disconnectSocket = ({ socketId }) =>
  POST({
    url: 'admin/live/sockets/disconnect',
    data: { socketId }
  })
