//@ts-check
import React, { useCallback } from 'react'
import { FormControl, FormHelperText, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

function AutocompleteSelect({
  name = '',
  value, //identifier
  title = '',
  onChange,
  options = [],
  valueKey = 'id',
  labelKey = 'name',
  helperText = '',
  showEmptyValue = true,
  isLoading = false,
  ...rest
}) {
  const handleChange = useCallback(
    (e, selectedOption, reason) => {
      onChange({ target: { name: name, value: selectedOption?.[valueKey] } })
    },
    [name, onChange, valueKey]
  )

  return (
    <FormControl margin='normal' fullWidth {...rest}>
      <Autocomplete
        value={options.find(option => option[valueKey] === value) ?? null}
        options={options}
        getOptionLabel={option => option?.[labelKey]}
        renderInput={params => (
          <TextField {...params} label={title} required={rest.required} />
        )}
        loading={isLoading}
        onChange={handleChange}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}

export default AutocompleteSelect
