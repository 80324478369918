import { MenuItem, TextField, Tooltip } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { isEmpty, propEq, sortBy, prop, omit } from 'ramda'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { findSubjects } from 'api/subjects'
import { getTeacherListQuery } from 'api/teachers'
import { getAvailableHoursBy } from 'api/teacherSchedules'
import { findSubscriptionsByStudent } from 'api/students'
import { dateTimeToString, toTimeString } from 'utils/date'
import config from 'config/index'
import useFormManager from '../../../../hooks/common/useFormManager'
import ConfirmModal from '../../../../shared/ConfirmModal'
import {
  AttendanceTypeLabels,
  AttendanceStatusLabels,
  AttendanceStatus,
  SubscriptionConfigTypes,
  SubjectLevels,
  AttendanceTypes
} from '../../../../shared/constants'
import DefaultSelect from '../../../../shared/DefaultSelect'

const REQUIREDS = [
  'subscriptionId',
  'subjectId',
  'teacherId',
  'date',
  'startTime',
  'endTime',
  'type',
  'status'
]
const WEEKS = 4

const makeInitialForm = (isCreateMode = true, attendance = {}) => {
  return {
    ...attendance,
    type: isCreateMode ? AttendanceTypes.NORMAL : attendance.type,
    status: isCreateMode ? AttendanceStatus.PLANNED : attendance.status,
    date: attendance.classStart ? new Date(attendance.classStart) : null,
    startTime: attendance.classStart
      ? toTimeString(attendance.classStart)
      : null,
    endTime: attendance.classEnd ? toTimeString(attendance.classEnd) : null
  }
}
function AttendanceSaveModal({
  studentId,
  attendance,
  onToggleShowModal,
  isSaving = false,
  isCreateMode = true,
  onSave
}) {
  const {
    form,
    handleFormChange,
    handleDateChange,
    clearForm,
    getErrorPropsByRequiredField,
    hasPendingRequiredFields
  } = useFormManager(REQUIREDS, makeInitialForm(isCreateMode, attendance))
  const [state, setState] = useState({
    subscriptions: [],
    subjects: [],
    teachers: [],
    availableTeacherHours: [],
    showTooltip: false
  })
  const handleFormSubscriptionChange = useCallback(
    e => {
      setState(state => ({ ...state, subjects: [], teachers: [] }))
      clearForm()
      isCreateMode &&
        handleFormChange({
          target: { name: 'status', value: AttendanceStatus.PLANNED }
        })
      handleFormChange(e)
    },
    [clearForm, handleFormChange, isCreateMode]
  )
  const handleSubjectChange = useCallback(
    e => {
      handleFormChange(e)
      handleFormChange({ target: { name: 'teacherId', value: '' } })
    },
    [handleFormChange]
  )
  const handleFormDateChange = useCallback(
    value => {
      handleDateChange('date', value)
      handleFormChange({ target: { name: 'startTime', value: null } })
      handleFormChange({ target: { name: 'endTime', value: null } })
    },
    [handleDateChange, handleFormChange]
  )
  const handleSave = useCallback(() => {
    onSave(form)
  }, [onSave, form])
  const selectedSubscription = useMemo(() => {
    return state.subscriptions?.find(propEq('id', form.subscriptionId))
  }, [form.subscriptionId, state.subscriptions])

  const hoursLimit = useMemo(() => {
    if (!selectedSubscription) return
    const { subscriptionType } = selectedSubscription
    switch (subscriptionType) {
      case SubscriptionConfigTypes.AGORA:
        return 'Mensual'
      default: {
        console.warn('Unknown type:', subscriptionType)
        return '??'
      }
    }
  }, [selectedSubscription])
  const availableHours = useMemo(() => {
    const start = []
    const end = []
    if (!isEmpty(state.availableTeacherHours))
      state.availableTeacherHours?.forEach(rangeHour => {
        const [left, right] = rangeHour.split('-')
        start.push({ id: left, name: left })
        end.push({ id: right, name: right })
      })
    return { start: sortBy(prop('id'), start), end: sortBy(prop('id'), end) }
  }, [state.availableTeacherHours])
  useEffect(() => {
    findSubscriptionsByStudent(studentId)
      .then(subscriptions =>
        setState(state => ({
          ...state,
          subscriptions: subscriptions.map(s => {
            s.customLabel = `${s.subscriptionCode} (${dateTimeToString(
              s.startDate
            )})`
            return s
          })
        }))
      )
      .catch(e => {
        console.error('Error getting subscriptions by student: ', e)
      })
  }, [studentId])
  useEffect(() => {
    if (form.subscriptionId) {
      const { selectedSubjectIds = [] } =
        state.subscriptions.find(({ id }) => id === form.subscriptionId) || {}
      findSubjects({
        _id: {
          $in: selectedSubjectIds
        }
      })
        .then(filteredSubjects => {
          setState(state => ({
            ...state,
            subjects: filteredSubjects.map(s => {
              s.customLabel =
                s.level === SubjectLevels.IDIOMAS && s.sublevel
                  ? `${s.name} (${s.sublevel})`
                  : `${s.name} (${s.level})`
              return s
            })
          }))
        })
        .catch(e =>
          console.error('Error fetching subjects by selected subscription: ', e)
        )
    }
  }, [form.subscriptionId, state.subscriptions, isCreateMode])
  useEffect(() => {
    if (form.subjectId) {
      getTeacherListQuery({ subjectId: form.subjectId, active: isCreateMode })
        .then(teachers => {
          setState(state => ({ ...state, teachers }))
        })
        .catch(e => console.error('Error getting teachers by subjectId: ', e))
    }
  }, [form.subjectId, isCreateMode, handleFormChange, handleFormDateChange])
  useEffect(() => {
    if (form.teacherId && form.date && !isNaN(Date.parse(form.date)))
      getAvailableHoursBy(form.date, form.teacherId)
        .then(data => {
          setState(state => ({ ...state, ...data }))
        })
        .catch(e =>
          console.error(
            'Error getting available hours by date and teacher: ',
            e
          )
        )
  }, [form.teacherId, form.date, handleFormChange])
  return (
    <ConfirmModal
      title={
        isCreateMode ? (
          'Crear asistencia'
        ) : (
          <Tooltip title={form?.id}>
            <span
              onClick={() => {
                navigator.clipboard.writeText(form?.id)
              }}
            >
              Editar asistencia
            </span>
          </Tooltip>
        )
      }
      subtitle='Solo se podrá guardar una asistencia válida'
      onOk={config.isDev ? handleSave : onToggleShowModal}
      onCancel={onToggleShowModal}
      okDisabled={config.isDev ? hasPendingRequiredFields : true}
      style={{ width: '65%', margin: '120px auto' }}
      isLoading={isSaving}
      showModal
    >
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '5vw'
        }}
      >
        <div>
          <DefaultSelect
            name='subscriptionId'
            value={form.subscriptionId || ''}
            title='Suscripción'
            options={state.subscriptions}
            labelKey='customLabel'
            onChange={handleFormSubscriptionChange}
            {...getErrorPropsByRequiredField('subscriptionId')}
          />
          <DefaultSelect
            name='subjectId'
            value={form.subjectId || ''}
            title='Asignatura'
            options={state.subjects}
            labelKey='customLabel'
            onChange={handleSubjectChange}
            {...getErrorPropsByRequiredField('subjectId')}
          />
          <DefaultSelect
            name='teacherId'
            value={form.teacherId || ''}
            title='Profesor'
            options={state.teachers}
            onChange={handleFormChange}
            {...getErrorPropsByRequiredField('teacherId')}
          />
          {attendance.studentComments && (
            <Tooltip title={attendance.studentComments} arrow>
              <TextField
                name='studentComments'
                value={attendance.studentComments || ''}
                label='Observaciones alumno'
                onChange={handleFormChange}
                disabled
                fullWidth
              />
            </Tooltip>
          )}
          <TextField
            name='description'
            value={form.description || ''}
            label='Observaciones admin'
            onChange={handleFormChange}
            margin='dense'
            fullWidth
          />
        </div>
        <div style={{ marginTop: 10 }}>
          <KeyboardDatePicker
            label='Fecha'
            margin='dense'
            format='dd/MM/yyyy'
            value={form.date || null}
            onChange={handleFormDateChange}
            disableToolbar
            clearable
            fullWidth
            {...getErrorPropsByRequiredField('date')}
          />
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '0.25fr 3fr 3fr',
              gap: 25
            }}
          >
            <div style={{ cursor: 'pointer' }}>
              <Tooltip
                title={
                  state.availableTeacherHours &&
                  form.date &&
                  !isEmpty(state.availableTeacherHours) ? (
                    <div>
                      {state.availableTeacherHours
                        .sort()
                        .map((option, index) => (
                          <MenuItem disabled key={index}>
                            {option}
                          </MenuItem>
                        ))}
                    </div>
                  ) : (
                    <h3>No hay horas disponibles en esa fecha</h3>
                  )
                }
                onClick={() =>
                  setState(state => ({
                    ...state,
                    showTooltip: !state.showTooltip
                  }))
                }
                open={state.showTooltip}
              >
                <p
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column'
                  }}
                >
                  <i className='material-icons'>info_outline</i>
                  <span>Horas</span>
                  <span>disponibles</span>
                </p>
              </Tooltip>
            </div>
            <DefaultSelect
              name='startTime'
              title='Hora inicio'
              margin='dense'
              options={availableHours.start}
              value={form.startTime || ''}
              onChange={handleFormChange}
              {...getErrorPropsByRequiredField('startTime')}
            />
            <DefaultSelect
              name='endTime'
              title='Hora fin'
              margin='dense'
              options={availableHours.end}
              value={form.endTime || ''}
              onChange={handleFormChange}
              {...getErrorPropsByRequiredField('endTime')}
            />
          </div>
          <div>
            <DefaultSelect
              name='type'
              value={form.type || ''}
              title='Tipo'
              options={Object.keys(omit(['test'], AttendanceTypeLabels)).reduce(
                (acc, key) => {
                  acc.push({ id: key, name: AttendanceTypeLabels[key] })
                  return acc
                },
                []
              )}
              margin='dense'
              onChange={handleFormChange}
              {...getErrorPropsByRequiredField('type')}
            />
            <DefaultSelect
              name='status'
              value={form.status || ''}
              disabled={!!isCreateMode}
              title='Estado'
              options={Object.keys(
                omit([AttendanceStatus.CANCELED_BY_APP], AttendanceStatusLabels)
              ).reduce((acc, key) => {
                acc.push({ id: key, name: AttendanceStatusLabels[key] })
                return acc
              }, [])}
              margin='dense'
              onChange={handleFormChange}
              {...getErrorPropsByRequiredField('status')}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop: 15,
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '5vw',
          color: 'rgba(0, 0, 0, 0.54)'
        }}
      >
        {Boolean(hoursLimit) && <div>Horas límite totales: {hoursLimit} </div>}
        {Boolean(selectedSubscription?.plannedHours) && (
          <div>Horas planificadas: {selectedSubscription.plannedHours}h</div>
        )}
      </div>
    </ConfirmModal>
  )
}

export default AttendanceSaveModal
