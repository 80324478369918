import { GET, POST } from './index'

export const listLogFiles = () =>
  GET({
    url: 'admin/scripts/list-log-files'
  })

export const calculateTimeIntervals = ({ studentId, files = [] }) =>
  POST({
    url: 'admin/scripts/time-intervals-by-student',
    data: { studentId, files }
  })
