import { GET } from 'api'

export const findAcademicSchedules = () =>
  GET({
    url: 'admin/academic-schedules/'
  })

export const syncAgoraAcademicSchedules = (startDate = '', endDate = '') =>
  GET({
    url: `admin/agora/sync-academic-schedule?startDate=${startDate}&endDate=${endDate}`
  })
