//@ts-check
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core'
import { not } from 'ramda'
import styles from './Dashboard.module.css'

function CollapseListItem({ title, icon, options = [], selected }) {
  const [open, setOpen] = useState(false)
  return (
    <div className={open ? styles.left_line : ''}>
      <ListItem
        button
        onClick={() => setOpen(not)}
        selected={selected}
        title={title}
      >
        <ListItemIcon className={styles.collapse_icon}>
          <i className='material-icons'>{icon}</i>
        </ListItemIcon>
        <ListItemText className={styles.text} primary={title} />
        {open ? (
          <i className='material-icons'>arrow_drop_down</i>
        ) : (
          <i className='material-icons'>arrow_drop_up</i>
        )}
      </ListItem>
      <Collapse in={open} timeout='auto' unmountOnExit>
        {options.map(({ title, linkTo, icon }, i) => {
          return (
            <Link key={i} className={styles.collapsed_item_link} to={linkTo}>
              <ListItem button>
                <ListItemIcon className={styles.collapse_icon}>
                  <i className='material-icons'>{icon}</i>
                </ListItemIcon>
                <ListItemText
                  className={styles.collapsed_item_text}
                  primary={title}
                />
              </ListItem>
            </Link>
          )
        })}
      </Collapse>
    </div>
  )
}

export default CollapseListItem
