//@ts-check
import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Checkbox, Chip, FormControlLabel, Tooltip } from '@material-ui/core'
import { findTickets, toggleTicketStatus } from 'api/ticketing'
import { getCurrentMonth, dateTimeToString } from 'utils/date'
import { textFilter } from 'utils/table'
import MonthSelector from 'elements/MonthSelector/MonthSelector'
import YearSelector from 'elements/YearSelector/YearSelector'
import { useMessageSnackbarActions } from 'elements/MessageContext/MessageContext'
import SearchInput from 'elements/SearchInput/SearchInput'
import InformationTable from 'components/InformationTable/InformationTable'
import { TicketStatus, TicketStatusLabels } from 'shared/constants'
import styles from './Ticketing.module.css'

function TicketingList() {
  const { setErrorMessage } = useMessageSnackbarActions()
  const [tickets, setTickets] = useState([])
  const [search, setSearch] = useState('')
  const [date, setDate] = useState(getCurrentMonth())
  const [showClosed, setShowClosed] = useState(false)

  const handleDateChange = key => e => {
    const value = e.target.value
    setDate(currentDate => ({
      ...currentDate,
      [key]: value
    }))
  }

  const fetchData = useCallback(
    () =>
      findTickets(date)
        .then(setTickets)
        .catch(() => setErrorMessage('No se puede mostrar el listado')),
    [date, setErrorMessage]
  )

  const handleToggleTicketStatus = id => {
    toggleTicketStatus(id)
      .then(fetchData)
      .catch(() =>
        setErrorMessage('Ha ocurrido un error cambiando el estado del ticket')
      )
  }

  const filterSearch = ticket => {
    return textFilter({
      object: ticket,
      fields: ['subject', 'studentName'],
      search: search
    })
  }

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const closedTickets = tickets.filter(
    ticket => ticket.status === TicketStatus.CLOSED
  )
  return (
    <>
      <div className={styles.toolbar}>
        <SearchInput
          value={search || ''}
          onChange={e => setSearch(e.target.value)}
        />
        <MonthSelector
          value={date.month}
          onChange={handleDateChange('month')}
        />
        <YearSelector value={date.year} onChange={handleDateChange('year')} />
        <FormControlLabel
          control={
            <Checkbox
              checked={showClosed}
              onChange={() => setShowClosed(state => !state)}
            />
          }
          label={`Ver cerrados (${closedTickets.length})`}
        />
      </div>
      <InformationTable
        className={styles.table}
        size='small'
        details={[
          { title: 'Asunto', key: 'subject', align: 'left' },
          { title: 'Alumno', key: 'student' },
          {
            title: 'Nº de mensajes',
            key: 'length'
          },
          {
            title: 'Estado',
            key: 'status'
          },
          {
            title: 'Fecha apertura',
            key: 'createdAt'
          },
          {
            title: 'Última interacción',
            key: 'updatedAt'
          }
        ]}
        data={tickets
          .filter(ticket => {
            if (showClosed) return ticket.status === TicketStatus.CLOSED
            return ticket.status === TicketStatus.OPEN
          })
          .filter(filterSearch)
          .map(ticket => ({
            subject: (
              <Link to={`/ticketing/${ticket.id}`}>{ticket.subject}</Link>
            ),
            student: (
              <Link to={`/students/${ticket.studentId}`}>
                {ticket.studentName}
              </Link>
            ),
            status: (
              <Tooltip
                title={ticket.status === TicketStatus.OPEN ? 'Cerrar' : 'Abrir'}
              >
                <Chip
                  label={TicketStatusLabels[ticket.status] || '??'}
                  size='small'
                  color={ticket.status === 'open' ? 'primary' : undefined}
                  onClick={() => handleToggleTicketStatus(ticket.id)}
                />
              </Tooltip>
            ),
            length: ticket.thread.length,
            createdAt: dateTimeToString(ticket.createdAt),
            updatedAt: dateTimeToString(ticket.updatedAt)
          }))}
      />
    </>
  )
}
export default TicketingList
