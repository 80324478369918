import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import { Card, CardContent, CardHeader } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { getAdminDetails } from 'api/admins'
import { useMessageSnackbarActions } from '../../elements/MessageContext/MessageContext'
import './FichaAdministrador.css'

const FichaAdministrador = () => {
  const { id } = useParams()
  const { setErrorMessage } = useMessageSnackbarActions()

  const [admin, setAdmin] = useState({ name: '', email: '', active: true })

  useEffect(() => {
    getAdminDetails({ adminId: id })
      .then(setAdmin)
      .catch(() => setErrorMessage('No se puede mostrar la ficha'))
  }, [id, setErrorMessage])

  return (
    <Card className='FichaAdministrador' variant='outlined'>
      <CardHeader title='Ficha' />
      <CardContent>
        <TextField margin='dense' label='Nombre' value={admin.name} fullWidth />
        <TextField
          margin='dense'
          label='Correo electrónico'
          value={admin.email}
          fullWidth
        />
        <FormControlLabel
          margin='dense'
          control={<Checkbox checked={admin.active} />}
          label='Activo'
        />
      </CardContent>
    </Card>
  )
}

export default FichaAdministrador
