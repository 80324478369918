//@ts-check
import React, { useEffect, useState } from 'react'
import defaultImage from 'assets/images/robot.png'
import styles from './TeacherAvatar.module.css'

function TeacherAvatar({ teacher, useThumbnail = false }) {
  const [isValidImage, setIsValidImage] = useState(false)
  const imageUrl = teacher.profileUrl

  useEffect(() => {
    const img = new Image()
    img.onload = function () {
      setIsValidImage(true)
    }
    img.onerror = function () {
      setIsValidImage(false)
    }
    img.src = imageUrl
  }, [imageUrl])
  if (useThumbnail)
    return (
      <div className={styles.profileThumbnailContainer}>
        <img
          src={isValidImage ? imageUrl : defaultImage}
          className={styles.profileThumbnail}
        />
      </div>
    )
  return (
    <div className={styles.profileImgContainer}>
      <img
        src={isValidImage ? imageUrl : defaultImage}
        className={styles.profileImg}
      />
    </div>
  )
}

export default TeacherAvatar
