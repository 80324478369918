import React from 'react'

const StatusIcon = ({
  active,
  positiveText = 'Activo',
  negativeText = 'Inactivo'
}) => {
  if (active)
    return (
      <i
        title={positiveText}
        className='material-icons'
        style={{ color: '#8cc63f' }}
      >
        done
      </i>
    )
  else
    return (
      <i
        title={negativeText}
        className='material-icons'
        style={{ color: '#f0513c' }}
      >
        clear
      </i>
    )
}

export default StatusIcon
