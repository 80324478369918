//@ts-check
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { createStudent } from 'api/students'
import { useMessageSnackbarActions } from '../../elements/MessageContext/MessageContext'
import { AccessTypes } from '../../shared/constants'
import StudentForm from './StudentForm'

const CrearAlumno = () => {
  const history = useHistory()
  const { setErrorMessage, setSuccessMessage } = useMessageSnackbarActions()

  const [isLoading, setLoading] = useState(false)

  const processSuccess = () => {
    setSuccessMessage('Alumno creado')
    history.push('/students')
  }

  const processError = error => {
    setLoading(false)
    setErrorMessage(error.message)
  }

  const handleSubmit = async form => {
    // @ts-ignore
    if (
      form.accessType === AccessTypes.STUDENT &&
      (!form.dateOfBirth || isNaN(Date.parse(form.dateOfBirth)))
    )
      return setErrorMessage('Introduzca una fecha correcta')
    setLoading(true)
    createStudent(form)
      .then(processSuccess)
      .catch(processError)
      .finally(() => setLoading(false))
  }

  return <StudentForm isLoading={isLoading} onSubmit={handleSubmit} />
}

export default CrearAlumno
