import { GET, POST, PUT } from 'api'

export const createBudget = (data = {}) =>
  POST({
    url: 'admin/budgets',
    data
  })
export const findBudgets = () =>
  GET({
    url: 'admin/budgets'
  })
export const getBudget = id =>
  GET({
    url: `admin/budgets/${id}`
  })
export const getFullBudgetInfoToCharge = id =>
  GET({
    url: `admin/budgets/${id}/full_budget_info_to_charge`
  })
export const updateBudget = (id, data = {}) =>
  PUT({
    url: `admin/budgets/${id}`,
    data
  })
export const createAgoraProposal = ({ studentId, month, year }) =>
  POST({
    url: 'admin/budgets/create-agora-proposal',
    data: { studentId, month, year }
  })

export const findProposalsByEvaluationId = evaluationId =>
  GET({ url: 'admin/budgets/by-evaluation/' + evaluationId })
