import { GET, PUT } from 'api'

export const findTickets = ({ month, year }) =>
  GET({
    url: `admin/ticketing?month=${month}&year=${year}`
  })
export const findSpecialRequests = ({ month, year }) =>
  GET({
    url: `admin/ticketing/special-requests?month=${month}&year=${year}`
  })
export const getTicketCount = ({ month, year, types = [] }) =>
  GET({
    url: `admin/ticketing/count?month=${month}&year=${year}&types=${JSON.stringify(
      types,
      null,
      2
    )}`
  })

export const getTicket = id =>
  GET({
    url: `admin/ticketing/${id}`
  })
export const addTicketMessage = (id, data = {}) =>
  PUT({
    url: `admin/ticketing/${id}`,
    data
  })
export const toggleTicketStatus = id =>
  PUT({
    url: `admin/ticketing/${id}/toggle-status`
  })
