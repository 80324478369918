import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { getTeacherListQuery } from 'api/teachers'
import { dateToString, dateFromString } from 'utils/date'
import { textFilter } from 'utils/table'
import InformationTable from '../../../components/InformationTable/InformationTable'
import SearchInput from '../../../elements/SearchInput/SearchInput'
import { useMessageSnackbarActions } from '../../../elements/MessageContext/MessageContext'
import './ProfesoresAsignatura.css'

const StatusIcon = ({ active }) => {
  if (active)
    return (
      <i title='Activo' className='material-icons' style={{ color: '#8cc63f' }}>
        done
      </i>
    )
  else
    return (
      <i
        title='Inactivo'
        className='material-icons'
        style={{ color: '#f0513c' }}
      >
        clear
      </i>
    )
}

const ProfesoresAsignatura = () => {
  const { id } = useParams()
  const { setErrorMessage } = useMessageSnackbarActions()

  const [teachers, setTeachers] = useState([])
  const [search, setSearch] = useState('')

  const filterSearch = subject => {
    return textFilter({
      object: subject,
      fields: ['name', 'email'],
      search: search
    })
  }

  useEffect(() => {
    getTeacherListQuery({ subjectId: id })
      .then(setTeachers)
      .catch(() => setErrorMessage('No se puede mostrar el listado'))
  }, [id, setErrorMessage])

  return (
    <div className='ProfesoresAsignatura'>
      <div className='ProfesoresAsignatura-filter'>
        <SearchInput value={search} onChange={e => setSearch(e.target.value)} />
      </div>
      <InformationTable
        details={[
          { title: 'Nombre', key: 'name', align: 'left' },
          {
            title: 'Email',
            key: 'email',
            sortFunction: (a, b) => dateFromString(a) - dateFromString(b)
          },
          { title: 'Último Login', key: 'lastLogin', sortDisabled: true },
          {
            title: 'Acceso',
            key: 'active',
            sortDisabled: true,
            align: 'center'
          }
        ]}
        data={teachers.filter(filterSearch).map(teacher => ({
          _id: teacher._id,
          email: teacher.email,
          name: (
            <Link to={`/staff/teachers/${teacher._id}`}>{teacher.name}</Link>
          ),
          lastLogin: teacher.lastSuccessfullLogin
            ? dateToString(teacher.lastSuccessfullLogin)
            : '--',
          active: <StatusIcon active={teacher.active} />
        }))}
      />
    </div>
  )
}

export default ProfesoresAsignatura
