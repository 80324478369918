import React from 'react'
import { Link } from 'react-router-dom'
import './StudentCell.css'

const StudentCell = ({ student }) => {
  if (!student) return ''

  return (
    <Link
      className='InformationTable-cell-student'
      to={`/students/${student._id}`}
    >
      {student.name}
    </Link>
  )
}

export default StudentCell
