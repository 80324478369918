// @ts-check
import React from 'react'
import { Button } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import styles from './ServiceTerms.module.css'

const makeUrl = () => {
  if (process.env.NODE_ENV === 'development')
    return window.location.hostname + ':3000/service-terms'
  return process.env.REACT_APP_STUDENT_URL + '/service-terms'
}
function ServiceTerms({ url }) {
  return url ? (
    <embed src={url} width='100%' height='100%' />
  ) : (
    <Alert
      className={styles.centered_alert}
      severity='info'
      color='warning'
      action={
        <Button
          size='small'
          color='primary'
          variant='outlined'
          onClick={() => {
            navigator.clipboard.writeText(makeUrl())
          }}
        >
          Copiar enlace
        </Button>
      }
    >
      Aún no ha firmado las condiciones de contratación
    </Alert>
  )
}

export default ServiceTerms
