import React from 'react'
import { Link } from 'react-router-dom'
import './TeacherCell.css'

const TeacherCell = ({ teacher }) => {
  if (!teacher) return ''

  return (
    <Link
      className='InformationTable-cell-teacher'
      to={`/staff/teachers/${teacher._id}`}
    >
      {teacher.name}
    </Link>
  )
}

export default TeacherCell
