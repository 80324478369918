import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { propEq, sortBy } from 'ramda'
import { findSubjectsWithTeacherCount, syncAgoraSubjects } from 'api/subjects'
import { textFilter } from 'utils/table'
import InformationTable from '../../components/InformationTable/InformationTable'
import SearchInput from '../../elements/SearchInput/SearchInput'
import { useMessageSnackbarActions } from '../../elements/MessageContext/MessageContext'
import ConfirmModal from 'shared/ConfirmModal'
import { Button } from '@material-ui/core'
import styles from './ListadoAsignaturas.module.css'

const StatusIcon = ({ active }) => {
  if (active)
    return (
      <i title='Activo' className='material-icons' style={{ color: '#8cc63f' }}>
        done
      </i>
    )
  else
    return (
      <i
        title='Inactivo'
        className='material-icons'
        style={{ color: '#f0513c' }}
      >
        clear
      </i>
    )
}

const ListadoAsignaturas = () => {
  const [search, setSearch] = useState('')
  const [subjects, setSubjects] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const { setErrorMessage, setSuccessMessage } = useMessageSnackbarActions()

  const filterSearch = subject => {
    return textFilter({
      object: subject,
      fields: ['name', 'type', 'subtype', 'level', 'sublevel', 'price'],
      search: search
    })
  }

  const openModal = () => {
    setShowModal(true)
  }
  const closeModal = () => {
    setShowModal(false)
  }
  const fetchData = () => {
    findSubjectsWithTeacherCount()
      .then(sortBy(list => !propEq('teacherCount', 0)(list)))
      .then(setSubjects)
      .catch(console.error)
  }
  useEffect(() => {
    fetchData()
  }, [])

  const handleSyncSubjects = () => {
    setIsLoading(true)
    syncAgoraSubjects()
      .then(data => {
        fetchData()
        data.failedIds && data.failedIds.length > 0
          ? setErrorMessage(
              `¡Atención! No se han podido sincronizar ${data.failedIds.length} asignaturas.`
            )
          : setSuccessMessage('Asignaturas sincronizadas exitosamente.')
      })
      .catch(error => {
        setErrorMessage('Error al sincronizar las asignaturas.')
        console.error('Error en la sincronización:', error)
      })
      .finally(() => {
        setIsLoading(false)
        closeModal()
      })
  }

  return (
    <div>
      <div className={styles.toolbar}>
        <SearchInput value={search} onChange={e => setSearch(e.target.value)} />

        <Button color='primary' variant='contained' onClick={openModal}>
          Sincronizar
        </Button>
      </div>
      <InformationTable
        className={styles.table}
        size='small'
        details={[
          { title: 'Nombre', key: 'name', align: 'left' },
          { title: 'Tipo', key: 'type' },
          { title: 'Subtipo', key: 'subtype' },
          { title: 'Nivel', key: 'level' },
          { title: 'Subnivel', key: 'sublevel' },
          { title: 'Precio', key: 'price' },
          { title: 'Nº profes', key: 'teacherCount' },
          { title: 'Activa', key: 'active' }
        ]}
        data={subjects.filter(filterSearch).map(subject => ({
          name: <Link to={`/subjects/${subject.id}`}>{subject.name}</Link>,
          type: subject.type,
          subtype: subject.subtype,
          level: subject.level,
          sublevel: subject.sublevel,
          price: `${subject.price} €/hora`,
          teacherCount: subject.teacherCount,
          active: <StatusIcon active={subject.active} />,
          style: !subject.teacherCount
            ? { background: 'var(--warning-color-30)' }
            : undefined
        }))}
      />
      {showModal && (
        <ConfirmModal
          title='Sincronización de Asignaturas: Ágora a Classfy'
          subtitle='¡Importante!'
          onOk={handleSyncSubjects}
          onCancel={closeModal}
          isLoading={isLoading}
          showModal
        >
          <p>
            Se llevará a cabo la <strong>sincronización</strong> de las{' '}
            <strong>asignaturas</strong> de <strong>Ágora</strong> con{' '}
            <strong>Classfy</strong>, lo que implica que las asignaturas{' '}
            <strong>deshabilitadas</strong> en <strong>Ágora</strong> también lo
            estarán en <strong>Classfy</strong>.
          </p>
        </ConfirmModal>
      )}
    </div>
  )
}

export default ListadoAsignaturas
