import React from 'react'
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core'
import './MiCuenta.css'
import { Switch, Route, Link } from 'react-router-dom'
import Informacion from '../../sections/Informacion/Informacion'

export default () => {
  return (
    <>
      <Drawer classes={{ paper: 'MiCuenta-drawer' }} variant='permanent'>
        <List>
          <ListItem button>
            <Link className='MiCuenta-drawer-link' to='/mi-cuenta/informacion'>
              <ListItemIcon className='MiCuenta-drawer-icon'>
                <i className='material-icons'>info</i>
              </ListItemIcon>
              <ListItemText
                className='MiCuenta-drawer-text'
                primary={'Información'}
              />
            </Link>
          </ListItem>
        </List>
      </Drawer>
      <div className='MiCuenta-area'>
        <Switch>
          <Route exact path='/mi-cuenta/informacion'>
            <Informacion />
          </Route>
        </Switch>
      </div>
    </>
  )
}
