import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Tooltip, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { getLiveSockets, disconnectSocket } from 'api/live'
import { dateToString } from 'utils/date'
import { textFilter } from 'utils/table'
import InformationTable from 'components/InformationTable/InformationTable'
import SearchInput from 'elements/SearchInput/SearchInput'
import LoadingButton from 'elements/LoadingButton/LoadingButton'
import LogTimeAnalyzer from './LogTimeAnalyzer'
import styles from './AlumnosConectados.module.css'

const TEACHER = 'TEACHER'
const STUDENT = 'STUDENT'

const getRoleInfo = socket => {
  if (socket.role === STUDENT) return socket.role
  const studentLength = socket.classroom?.students.length

  return studentLength
    ? `${socket.role} (${studentLength} alumnos)`
    : `${socket.role} (sin alumnos)`
}
const getTooltipInfo = (currentSocket = {}, sockets = []) => {
  const { role, classroom } = currentSocket
  if (role === STUDENT)
    return sockets.find(({ _id }) => _id === classroom.teacher)?.name || ''
  if (role === TEACHER)
    return sockets
      .filter(({ _id }) => classroom?.students?.includes(_id))
      .map(student => student?.name)
  return '---'
}

const ProfileLink = ({ role, _id, name, info }) => {
  if (role === TEACHER)
    return (
      <Tooltip
        title={
          <>
            Alumnos:
            {info.map((studentName, i) => (
              <div key={i}>
                {studentName}
                <br />
              </div>
            ))}
          </>
        }
      >
        <Link to={`/staff/teachers/${_id}`}>{name}</Link>
      </Tooltip>
    )
  if (role === STUDENT)
    return (
      <Tooltip
        title={
          <>
            Profesor:
            <br />
            {info}
          </>
        }
      >
        <Link to={`/students/${_id}`}>{name}</Link>
      </Tooltip>
    )
  return '--'
}

const AlumnosConectados = () => {
  const [search, setSearch] = useState('')
  const [sockets, setSockets] = useState([])
  const [showModal, setShowModal] = useState(false)

  const fetchData = useCallback(() => {
    getLiveSockets()
      .then(data => {
        setSockets(
          data.map(connection => ({
            ...connection,
            connectionDate: new Date(connection.connectionTS)
          }))
        )
      })
      .catch(console.error)
  }, [])
  const handleDisconnect = useCallback(
    socketId => {
      disconnectSocket({ socketId })
        .then(fetchData)
        .catch(e => {
          console.error('Error disconnecting socket: ', e)
          fetchData()
        })
    },
    [fetchData]
  )

  const filterSearch = live => {
    return textFilter({
      object: live,
      fields: ['name', 'role'],
      search: search
    })
  }

  useEffect(() => {
    console.log(sockets)
  }, [sockets])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const orderedData = useMemo(() => {
    const studentWithoutTeacher = sockets.filter(socket => {
      if (socket.role === STUDENT && !socket.classroom?.teacher) {
        socket.customStyle = { background: 'var(--secondary-color-30)' }
        socket.customInfo = 'En sala de espera'
        return socket
      }
    })
    const studentsInBackground = sockets.filter(socket => {
      if (socket.role === STUDENT) {
        const teacher = sockets.find(
          ({ _id }) => socket.classroom?.teacher === _id
        )
        if (!teacher) return
        if (!teacher.classroom?.students?.includes(socket._id)) {
          socket.customStyle = { background: 'var(--warning-color-30)' }
          socket.customInfo = '¡Necesita atención!'
          return socket
        }
      }
    })
    const teacherWithStudentsInOrder = sockets.reduce((acc = [], socket) => {
      if (socket.role === TEACHER) {
        socket.customInfo = getRoleInfo(socket)
        socket.classroom.students.forEach(studentId => {
          if (studentWithoutTeacher.map(({ _id }) => _id).includes(studentId)) {
            socket.customStyle = { background: 'var(--warning-color-10)' }
            return
          }
          if (!sockets.find(s => s._id === studentId))
            socket.customStyle = { background: 'var(--warning-color-10)' }
        })
        acc.push(socket)
        const studentIds = socket.classroom?.students
        if (!!studentIds.length)
          studentIds.forEach(studentId => {
            const student = sockets.find(
              s => s._id === studentId && s.classroom?.teacher === socket._id
            )
            student && acc.push(student)
          })
      }
      return acc
    }, [])

    return studentsInBackground
      .concat(studentWithoutTeacher)
      .concat(teacherWithStudentsInOrder)
  }, [sockets])

  return (
    <div>
      <div className={styles.toolbar}>
        <SearchInput value={search} onChange={e => setSearch(e.target.value)} />
        <LoadingButton
          label='Intervalos de tiempo'
          startIcon={<i className='material-icons'>av_timer</i>}
          variant='outlined'
          onClick={() => setShowModal(true)}
        />
        <Typography variant='button' color='textSecondary'>
          Hay {orderedData.length} usuarios conectados
        </Typography>
      </div>
      <InformationTable
        className={styles.table}
        size='small'
        details={[
          { title: 'Nombre', key: 'name', align: 'left' },
          { title: 'Info', key: 'info', sortDisabled: true, align: 'center' },
          { title: 'Dispositivo', key: 'device' },
          { title: 'Hora conexión', key: 'connectionTS', sortDisabled: true },
          { title: 'Opciones', key: 'options', sortDisabled: true }
        ]}
        data={orderedData.filter(filterSearch).map(socket => ({
          _id: socket._id,
          name: (
            <ProfileLink
              _id={socket._id}
              name={socket.name}
              role={socket.role}
              info={getTooltipInfo(socket, sockets)}
            />
          ),
          device: socket.device,
          info: socket.customInfo,
          connectionTS: dateToString(socket.connectionTS),
          options: (
            <Button
              color='primary'
              onClick={() => handleDisconnect(socket._id)}
            >
              Desconectar
            </Button>
          ),
          style: socket.customStyle
        }))}
      />
      {showModal && <LogTimeAnalyzer onClose={() => setShowModal(false)} />}
    </div>
  )
}

export default AlumnosConectados
