//@ts-check
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { IconButton } from '@material-ui/core'
import { getTeacherSchedule } from 'api/teacherSchedules'
import {
  getMonthDaysInfo,
  isSameDay,
  MONTHS,
  DAY_NAME_BY_NUMBER
} from 'utils/date'
import PageLoading from 'components/PageLoading/PageLoading'
import { useMessageSnackbarActions } from 'elements/MessageContext/MessageContext'
import styles from './TeacherSchedule.module.css'

const DayCell = ({ day, selectedMonth, onGetHoursByDate }) => {
  let dayClassName = 'date_identifier'
  let cellClassName = 'calendar_day'
  if (day.getMonth() === selectedMonth) dayClassName += ' active'
  if (isSameDay(new Date(), day)) {
    dayClassName += ' today'
    cellClassName += ' today'
  }
  return (
    <td className={cellClassName}>
      <div className={dayClassName}>{day.getDate()}</div>
      <div className={styles.hourList}>
        {onGetHoursByDate(day).map((hour, i) => (
          <p key={i} className={styles.hourListItem}>
            {hour}
          </p>
        ))}
      </div>
    </td>
  )
}

const WeekRow = ({ week, selectedMonth, onGetHoursByDate }) => {
  return (
    <tr className='calendar_week'>
      {week.map((day, i) => (
        <DayCell
          key={i}
          day={day}
          selectedMonth={selectedMonth}
          onGetHoursByDate={onGetHoursByDate}
        />
      ))}
    </tr>
  )
}

function TeacherSchedule() {
  const [hoursByDate, setHoursByDate] = useState([])
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth())
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
  const [isFetching, setIsFetching] = useState(false)

  // @ts-ignore
  const { id: teacherId } = useParams()
  const { setSuccessMessage, setErrorMessage } = useMessageSnackbarActions()

  const weeks = useMemo(
    () => getMonthDaysInfo(selectedYear, selectedMonth),
    [selectedYear, selectedMonth]
  )

  const changeMonth = value => {
    setSelectedMonth(prevMonth => {
      let newMonth = prevMonth + value
      let newYear = selectedYear
      if (newMonth < 0) {
        newMonth = 11
        newYear = selectedYear - 1
      } else if (newMonth > 11) {
        newMonth = 0
        newYear = selectedYear + 1
      }
      setSelectedYear(newYear)
      return newMonth
    })
  }

  const fetchData = useCallback(() => {
    getTeacherSchedule({
      teacherId,
      month: selectedMonth,
      year: selectedYear
    })
      .then(setHoursByDate)
      .catch(e => {
        console.error('Error getting default schedule: ', e)
        setErrorMessage(
          'Ha habido un error obteniendo el horario por defecto: ' + e.message
        )
      })
      .finally(() => setIsFetching(false))
  }, [teacherId, selectedMonth, selectedYear, setErrorMessage])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const handleGetHoursByDate = selectedDate => {
    return hoursByDate[selectedDate.getTime()] || []
  }

  return (
    <PageLoading className={styles.teacherSchedule} isLoading={isFetching}>
      <div>
        <div className={styles.calendarContainer}>
          <div>
            <div>
              <IconButton onClick={() => changeMonth(-1)}>
                <i className='material-icons'>arrow_back</i>
              </IconButton>
              <h2 className='calendar_month_name'>{`${MONTHS[selectedMonth]} de ${selectedYear}`}</h2>
              <IconButton onClick={() => changeMonth(1)}>
                <i className='material-icons'>arrow_forward</i>
              </IconButton>
            </div>
            <table className={styles.calendarElement}>
              <thead>
                <tr>
                  {DAY_NAME_BY_NUMBER.map(({ label, day }) => {
                    return (
                      <th key={day} className={styles.calendarElementHeader}>
                        {label}
                      </th>
                    )
                  })}
                </tr>
              </thead>
              <tbody>
                {weeks.map((week, i) => (
                  <WeekRow
                    key={i}
                    week={week}
                    selectedMonth={selectedMonth}
                    onGetHoursByDate={handleGetHoursByDate}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </PageLoading>
  )
}

export default TeacherSchedule
