import React, { useEffect, useState } from 'react'
import { identity, prop, sortBy } from 'ramda'
import { Chip, FormHelperText } from '@material-ui/core'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { findSubjects } from 'api/subjects'
import { SubjectLevels } from '../../shared/constants'
import Spinner from '../Spinner/Spinner'
import './SubjectSelector.css'

const SubjectSelector = ({
  value,
  onChange = x => x,
  margin = 'normal',
  label = 'Asignaturas',
  multiple = false,
  name = '',
  helperText = '',
  filter = identity,
  ...rest
}) => {
  const [subjects, setSubjects] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    findSubjects()
      .then(sortBy(prop('name')))
      .then(setSubjects)
      .catch(e => console.error('Error getting subjects: ', e))
      .finally(() => setIsLoading(false))
  }, [])

  const getChipLabel = subjectId => {
    const subject = subjects.find(s => s._id === subjectId)
    if (!subject) return '???'
    else return `${subject.name} (${subject.level})`
  }

  return (
    <FormControl className='SubjectSelector' margin={margin} {...rest}>
      <InputLabel id='selector-asignatura'>{label}</InputLabel>
      <Select
        name={name}
        labelId='selector-asignatura'
        id='selector-asignatura-select'
        value={value}
        multiple={multiple}
        onChange={onChange}
        input={
          isLoading ? <Spinner /> : <Input id='selector-asignatura-chip' />
        }
        renderValue={selected => {
          return multiple ? (
            <div className='SubjectMultipleSelector-chips'>
              {selected.map(value => (
                <Chip
                  key={value}
                  label={getChipLabel(value)}
                  className='SubjectMultipleSelector-chip'
                />
              ))}
            </div>
          ) : (
            getChipLabel(value)
          )
        }}
      >
        {subjects
          .filter(subject => subject.active)
          .filter(filter)
          .map(subject => (
            <MenuItem key={subject._id} value={subject._id}>
              {subject.level === SubjectLevels.IDIOMAS && subject.sublevel
                ? `${subject.name} (${subject.sublevel})`
                : `${subject.name} (${subject.level})`}
            </MenuItem>
          ))}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}

export default SubjectSelector
