//@ts-check
import { useCallback, useEffect, useState } from 'react'
import { assoc, assocPath } from 'ramda'
import { findSubjects } from 'api/subjects'

function makeSubjectLabel(subject) {
  let name = subject.name
  if (subject.sublevel) {
    name += ` (${subject.level} - ${subject.sublevel})`
    return name
  }
  return (name += ` (${subject.level})`)
}

/**
 *
 * @param {{fetchOnMount?: boolean}} [options]
 * @returns {{subjects: Array, subjectsById: object, isFetching: boolean, fetchSubjects: (query?: object)=>void}}
 */
export default function useSubjects(options) {
  const currentOptions = {
    fetchOnMount: true,
    ...(options || {})
  }
  const { fetchOnMount } = currentOptions
  const [{ subjects, subjectsById, isFetching }, setState] = useState({
    subjects: [],
    subjectsById: {},
    isFetching: false
  })

  const fetchSubjects = useCallback((query = {}) => {
    setState(assoc('isFetching', true))
    findSubjects(query)
      .then(subjects => {
        setState(state => ({
          ...state,
          subjects: subjects.map(s => {
            s.label = makeSubjectLabel(s)
            s.value = s.id
            return s
          }),
          subjectsById: subjects.reduce(
            (acc, subject) => assocPath([subject.id], subject, acc),
            {}
          ),
          isFetching: false
        }))
      })
      .catch(e => {
        console.error('Error fetching subjects:', e)
        setState(assoc('isFetching', false))
      })
  }, [])

  useEffect(() => {
    fetchOnMount && fetchSubjects()
  }, [fetchOnMount, fetchSubjects])
  return {
    subjects,
    subjectsById,
    isFetching,
    fetchSubjects
  }
}
