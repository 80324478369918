import React from 'react'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { SubjectTypes } from '../../shared/constants'
import './SubjectTypeSelector.css'

const options = Object.values(SubjectTypes)

const SubjectTypeSelector = ({ value, onChange, ...rest }) => (
  <FormControl className='SubjectTypeSelector' margin='normal' {...rest}>
    <InputLabel id='selector-tipo-asignatura'>Tipo</InputLabel>
    <Select
      labelId='selector-tipo-asignatura'
      value={value}
      onChange={onChange}
      input={<Input />}
    >
      {options.map(type => (
        <MenuItem key={type} value={type}>
          {type}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

export default SubjectTypeSelector
