//@ts-check
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Button, Card, CardActions, CardHeader } from '@material-ui/core'
import { isEmpty } from 'ramda'
import useSubjects from 'hooks/common/useSubjects'
import { getBudget } from 'api/budgets'
import styles from './BudgetDetails.module.css'
import { SubscriptionCodeLabels, SubscriptionCodes } from 'shared/constants'

function BudgetDetails() {
  // @ts-ignore
  const { id } = useParams()
  const [budget, setBudget] = useState({})
  const [isFetching, setIsFetching] = useState(false)
  const { subjectsById, isFetching: isFetchingSubjects } = useSubjects()

  const hasToFetch = id && /([a-f0-9]{24})/.test(id)
  useEffect(() => {
    if (hasToFetch) {
      setIsFetching(true)
      getBudget(id)
        .then(setBudget)
        .catch(console.error)
        .finally(() => setIsFetching(false))
    }
  }, [hasToFetch, id])

  if (isFetching || isFetchingSubjects) return null
  if (hasToFetch) {
    return budget && !isEmpty(budget) ? (
      <AgoraSummary budget={budget} subjectsById={subjectsById} />
    ) : (
      <Card>
        <CardHeader
          title='Presupuesto no encontrado'
          subheader='Puede haber sido eliminado o no existe'
        />
        <CardActions>
          <Link to='/budgets'>
            <Button variant='contained' color='primary'>
              Volver
            </Button>
          </Link>
        </CardActions>
      </Card>
    )
  }
}

export default BudgetDetails

function AgoraSummary({ budget = {}, subjectsById = {} }) {
  const { config = {}, selectedSubjectIds = [] } = budget || {}

  return (
    <div className={styles.card}>
      <div className={styles.subscriptionDetails}>
        <h4 className={styles.proposalName}>
          {SubscriptionCodeLabels[config?.code] || '???'}
        </h4>
        <p>{config?.hours}/horas</p>
      </div>

      <div className={styles.subjectsContainer}>
        {selectedSubjectIds.map((selectedSubjectId, index) => {
          return (
            <div className={styles.subject} key={index}>
              <p>{subjectsById[selectedSubjectId]?.label}</p>
              <p>{makeText(config, selectedSubjectId)}</p>
            </div>
          )
        })}
      </div>
      <div className={styles.divider} />
      <div className={styles.totalContainer}>
        <p className={styles.totalLabel}>Total </p>
        <p className={styles.totalPrice}>{budget.totalPrice}€/mes</p>
      </div>

      <p>
        * Este presupuesto ha sido configurado para el{' '}
        <strong>
          {('0' + (config?.projection?.month + 1)).slice(-2)}/
          {config?.projection?.year}
        </strong>
      </p>
    </div>
  )
}

function makeText(config = {}, selectedSubjectId) {
  switch (config?.code) {
    case SubscriptionCodes.NORMAL:
      return makeNormalText(config.projection, selectedSubjectId)
    case SubscriptionCodes.MORNINGS:
      return makeMorningsText(config.projection, selectedSubjectId)
    case SubscriptionCodes.OTHERS:
    case SubscriptionCodes.PACK:
      return '-'
    default: {
      console.warn('Unknown code: ' + config?.code)
      return ''
    }
  }
}

function makeNormalText(projection = {}, selectedSubjectId) {
  const hour = projection.week?.[selectedSubjectId]
  if (!hour) return '-'
  return `${hour}h/semana`
}
function makeMorningsText(projection = {}, selectedSubjectId) {
  const hour = projection.hoursBySubjectId?.[selectedSubjectId]
  if (!hour) return '-'
  return `${hour}h/mes`
}
