import React, { useEffect } from 'react'
import { Redirect, Switch, Route, useHistory } from 'react-router-dom'
import { Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import Header from '../Header/Header'
import PageContent from '../PageContent/PageContent'
import MiCuenta from '../../views/MiCuenta/MiCuenta'
import { isAuthenticated, clearAuthentication } from '../../utils'
import Dashboard from '../../views/Dashboard/Dashboard'
import {
  useMessageSnackbar,
  useMessageSnackbarActions
} from '../../elements/MessageContext/MessageContext'

const Layout = () => {
  const snackbar = useMessageSnackbar()
  const snackbarActions = useMessageSnackbarActions()
  const history = useHistory()

  useEffect(() => {
    if (!snackbar || !snackbar.message) return
    let isCurrent = true
    setTimeout(
      () => {
        if (!isCurrent) return
        snackbarActions.clear()
      },
      snackbar.type === 'error' ? 7000 : 5000
    )
    return () => (isCurrent = false)
  }, [snackbar, snackbarActions])

  const handleLogout = () => {
    clearAuthentication()
    history.push('/login')
  }

  if (!isAuthenticated()) return <Redirect to='/login' />

  return (
    <>
      <Header onLogout={handleLogout} />
      <PageContent>
        <Switch>
          <Route path='/mi-cuenta' render={() => <MiCuenta />} />
          <Route path='/' render={() => <Dashboard />} />
        </Switch>
      </PageContent>
      <Snackbar
        open={!!snackbar.message}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert elevation={6} variant='filled' severity={snackbar.type}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  )
}

export default Layout
