import React from 'react'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core'
import { GoogleOAuthProvider } from '@react-oauth/google'
import DateFnsUtils from '@date-io/date-fns'
import esLocale from 'date-fns/locale/es'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import config from 'config'
import App from './App'
import theme from './utils/theme'
import MessageContext from './elements/MessageContext/MessageContext'
import './index.css'

const queryClient = new QueryClient()

const app = (
  <GoogleOAuthProvider clientId={config.google.clientId}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <MessageContext>
            <QueryClientProvider client={queryClient}>
              <App />
            </QueryClientProvider>
          </MessageContext>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </BrowserRouter>
  </GoogleOAuthProvider>
)

ReactDOM.render(app, document.getElementById('root'))
