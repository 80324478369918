import { GET, POST, PUT } from 'api'

export const findAttendanceDTOs = ({
  date = null,
  visibleByDate = null,
  studentId = '',
  subscriptionId = ''
}) => {
  let URL = 'admin/attendances?'
  let params = []
  if (date) params.push(`date=${new Date(date).toDateString()}`)
  if (visibleByDate)
    params.push(`visibleByDate=${new Date(visibleByDate).toDateString()}`)
  if (studentId) params.push(`studentId=${studentId}`)
  if (subscriptionId) params.push(`subscriptionId=${subscriptionId}`)
  return GET({
    url: URL + params.join('&')
  })
}

export const saveAttendance = (data = {}) =>
  POST({
    url: 'admin/attendances',
    data
  })

export const updateAttendance = (id, data = {}) =>
  PUT({
    url: `admin/attendances/${id}`,
    data
  })

export const saveManyAttendances = (data = {}) =>
  POST({
    url: 'admin/attendances/save-many-attendances',
    data
  })

export const createTestAttendance = (data = {}) =>
  POST({
    url: 'admin/attendances/test',
    data
  })
/**
 * @param {string} id
 * @param {object} data
 */
export const updateTestAttendance = (id, data = {}) =>
  PUT({
    url: `admin/attendances/test/${id}`,
    data
  })

export const getTestAttendanceCount = id =>
  GET({
    url: `admin/attendances/test-attendance-count/${id}`
  })

/**
 * @param {string} startDate DD-MM-YYY
 * @param {string} endDate DD-MM-YYY
 */
export const syncAgoraAttendances = (startDate = '', endDate = '') =>
  GET({
    url: `admin/agora/sync-attendances?startDate=${startDate}&endDate=${endDate}`
  })
