import { Tooltip } from '@material-ui/core'
import React, { useState } from 'react'
import useFormManager from '../../../../hooks/common/useFormManager'
import ConfirmModal from '../../../../shared/ConfirmModal'
import TestAttendanceForm from '../../../../shared/TestAttendanceForm'

const REQUIREDS = ['subjectId', 'teacherId', 'classStart', 'classEnd', 'type']

function TestAttendanceSaveModal({
  attendance = {},
  onToggleShowModal,
  isSaving = false,
  onSave
}) {
  const isCreateMode = !attendance?.id
  const [state, setState] = useState({
    form: attendance
  })
  const { fastCheck } = useFormManager()
  const { form } = state
  const okDisabled = fastCheck(REQUIREDS, form) || isSaving
  return (
    <ConfirmModal
      title={
        isCreateMode ? (
          'Crear asistencia'
        ) : (
          <Tooltip title={form?.id}>
            <span
              onClick={() => {
                navigator.clipboard.writeText(form?.id)
              }}
            >
              Editar asistencia
            </span>
          </Tooltip>
        )
      }
      subtitle='Solo se podrá guardar una asistencia válida'
      onOk={() => onSave(form)}
      onCancel={onToggleShowModal}
      okDisabled={okDisabled}
      style={{ width: '50%', margin: '120px auto' }}
      isLoading={isSaving}
      showModal
    >
      <TestAttendanceForm
        onSave={form => setState(state => ({ ...state, form }))}
        attendance={attendance}
      />
    </ConfirmModal>
  )
}

export default TestAttendanceSaveModal
