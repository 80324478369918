//@ts-check
import React, { useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from 'react-query'
import { Button, Chip, TablePagination } from '@material-ui/core'
import { assoc } from 'ramda'
import { findStudents } from 'api/students'
import { dateToString, dateFromString, dateToStringV2 } from 'utils/date'
import InformationTable from '../../components/InformationTable/InformationTable'
import SearchInput from '../../elements/SearchInput/SearchInput'
import LoadingButton from '../../elements/LoadingButton/LoadingButton'
import StatusIcon from '../../shared/StatusIcon'
import Spinner from '../../elements/Spinner/Spinner'
import { useMessageSnackbarActions } from '../../elements/MessageContext/MessageContext'
import { AccessTypeLabels } from '../../shared/constants'
import { syncAgoraAttendances } from 'api/attendances'
import ConfirmModal from 'shared/ConfirmModal'
import { KeyboardDatePicker } from '@material-ui/pickers'
import styles from './ListadoAlumnos.module.css'

const initialPagination = { page: 1, pageSize: 15 }
const project = [
  'id',
  'name',
  'email',
  'lastSuccessfullLogin',
  'active',
  'accessType'
]

const ListadoAlumnos = () => {
  const [search, setSearch] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [pagination, setPagination] = useState(initialPagination)
  const [agoraAttendance, setAgoraAttendance] = useState({
    startDate: new Date(),
    endDate: null
  })
  const { setSuccessMessage, setErrorMessage } = useMessageSnackbarActions()
  const query = useMemo(() => {
    if (search)
      return {
        useCount: true,
        $or: [
          { key: 'name', search: search, isRegExp: true },
          { key: 'email', search: search, isRegExp: true }
        ]
      }
    return { useCount: true }
  }, [search])

  const { isFetching, data = {} } = useQuery(
    ['student-list', search, pagination],
    () => findStudents(query, project, pagination),

    {
      refetchOnWindowFocus: false,
      onError(e) {
        setErrorMessage()
        console.error('Error fetching students: ', e)
      }
    }
  )
  const { students = [], count = 0 } = data

  const openModal = () => {
    setIsModalOpen(true)
  }
  const closeModal = () => {
    setIsModalOpen(false)
  }
  const handleStartDateChange = startDate =>
    setAgoraAttendance(prevState => ({
      ...prevState,
      startDate
    }))

  const handleEndDateChange = endDate =>
    setAgoraAttendance(prevState => ({
      ...prevState,
      endDate
    }))

  const handleSearch = useCallback(e => {
    setSearch(e.target.value)
    setPagination(initialPagination)
  }, [])

  const handleChangePage = useCallback((_, page) => {
    setPagination(assoc('page', page + 1))
  }, [])

  const handleSyncAttendances = () => {
    setIsLoading(true)
    syncAgoraAttendances(
      dateToStringV2(agoraAttendance.startDate),
      dateToStringV2(agoraAttendance.endDate)
    )
      .then(data => {
        data.failedIds && data.failedIds.length > 0
          ? setErrorMessage(
              `¡Atención! No se han podido sincronizar ${data.failedIds.length} asistencias`
            )
          : setSuccessMessage('Asistencias sincronizadas exitosamente.')
      })
      .catch(error => {
        setErrorMessage('Error al sincronizar asistencias.')
        console.error('Error en la sincronización:', error)
      })
      .finally(() => {
        setIsLoading(false)
        closeModal()
      })
  }

  if (isFetching) return <Spinner />
  return (
    <div>
      <div className={styles.toolbar}>
        <SearchInput
          value={search}
          onChange={handleSearch}
          useDebounced
          autoFocus
        />

        <div className={styles.buttonContainer}>
          <Link to='/students/create'>
            <LoadingButton
              label='Nuevo alumno'
              // @ts-ignore
              startIcon={<i className='material-icons'>add</i>}
            />
          </Link>
          <Button color='primary' variant='contained' onClick={openModal}>
            Sincronizar asistencias
          </Button>
        </div>
      </div>

      <InformationTable
        className={styles.table}
        details={[
          { title: 'Nombre', key: 'name', align: 'left' },
          {
            title: 'Email',
            key: 'email',
            // @ts-ignore
            sortFunction: (a, b) => dateFromString(a) - dateFromString(b)
          },
          { title: 'Tipo', key: 'accessType', sortDisabled: true },
          { title: 'Último Login', key: 'lastLogin', sortDisabled: true },
          {
            title: 'Activo',
            key: 'active',
            sortDisabled: true,
            align: 'center'
          }
        ]}
        data={students.map(student => ({
          email: student.email,
          name: <Link to={`/students/${student.id}`}>{student.name}</Link>,
          accessType: (
            <Chip
              label={AccessTypeLabels[student.accessType]}
              color={student.accessType === 'student' ? 'primary' : undefined}
            />
          ),
          lastLogin: student.lastSuccessfullLogin
            ? dateToString(student.lastSuccessfullLogin)
            : '--',
          active: <StatusIcon active={student.active} />
        }))}
        size='small'
      />
      <TablePagination
        rowsPerPageOptions={[]}
        component='div'
        count={count}
        rowsPerPage={pagination.pageSize}
        page={pagination.page - 1}
        onPageChange={handleChangePage}
      />
      {isModalOpen && (
        <ConfirmModal
          title='Sincronización de asistencias: Ágora a Classfy'
          subtitle='¡Atención! Asegúrate de seleccionar la fecha de fin adecuada.'
          onOk={handleSyncAttendances}
          onCancel={closeModal}
          isLoading={isLoading}
          okDisabled={!agoraAttendance.startDate || !agoraAttendance.endDate}
          showModal
        >
          <p>
            Se llevará a cabo la <strong>sincronización</strong> de las{' '}
            <strong>asistencias</strong> de <strong>Ágora</strong> con{' '}
            <strong>Classfy</strong>.
          </p>
          <div className='datePickerContainer'>
            <KeyboardDatePicker
              label='Fecha de inicio'
              margin='dense'
              format='dd/MM/yyyy'
              value={agoraAttendance.startDate || null}
              onChange={handleStartDateChange}
              fullWidth
            />
            <KeyboardDatePicker
              label='Fecha de fin'
              margin='dense'
              format='dd/MM/yyyy'
              value={agoraAttendance.endDate || null}
              onChange={handleEndDateChange}
              fullWidth
            />
          </div>
        </ConfirmModal>
      )}
    </div>
  )
}

export default ListadoAlumnos
