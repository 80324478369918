import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { getTeacherClasses } from 'api/teachers'
import { dateFromString, dateTimeToString } from 'utils/date'
import InformationTable from '../../../components/InformationTable/InformationTable'
import DateSelector from '../../../elements/DateSelector/DateSelector'
import StudentCell from '../../../components/InformationTable/cells/StudentCell/StudentCell'
import './ClasesProfesor.css'

const getDuration = ({ start, end }) => {
  const minutes = Math.ceil((end - start) / 60000)
  const hours = Math.floor(minutes / 60)
  if (hours > 0) {
    if (minutes % 60 > 0) return `${hours}h ${minutes % 60}m`
    return `${hours}h`
  }
  return `${minutes}m`
}

const ClasesProfesor = () => {
  const { id } = useParams()
  const [date, setDate] = useState(new Date())
  const [classes, setClasses] = useState([])

  useEffect(() => {
    setClasses([])
    getTeacherClasses({ teacherId: id, date })
      .then(data =>
        setClasses(
          data.map(teacherClass => ({
            ...teacherClass,
            classStart: new Date(teacherClass.classStart),
            classEnd: new Date(teacherClass.classEnd)
          }))
        )
      )
      .catch(console.error)
  }, [id, date])

  const handleDateChange = date => setDate(date)

  return (
    <div className='ClasesProfesor'>
      <div className='ClasesProfesor-filter'>
        <DateSelector value={date} onChange={handleDateChange} />
      </div>
      <InformationTable
        details={[
          {
            title: 'Alumno',
            key: 'student',
            sortDisabled: true,
            align: 'left'
          },
          {
            title: 'Inicio clase',
            key: 'start',
            sortFunction: (a, b) => dateFromString(a) - dateFromString(b)
          },
          {
            title: 'Fin clase',
            key: 'end',
            sortFunction: (a, b) => dateFromString(a) - dateFromString(b)
          },
          { title: 'Duracion', key: 'length', sortDisabled: true }
        ]}
        data={classes.map(teacherClass => ({
          _id: teacherClass._id,
          start: dateTimeToString(teacherClass.classStart),
          end: dateTimeToString(teacherClass.classEnd),
          student: <StudentCell student={teacherClass.student} />,
          length: getDuration({
            start: teacherClass.classStart,
            end: teacherClass.classEnd
          })
        }))}
      />
    </div>
  )
}

export default ClasesProfesor
