import { AppBar, Tab, Tabs } from '@material-ui/core'
import React, { useState } from 'react'
import ClasesProfesor from './tabs/ClasesProfesor'
import DatosProfesor from './tabs/DatosProfesor'
import TeacherSchedule from './tabs/TeacherSchedule'
import './FichaProfesor.css'

const TabPanel = ({ active, children }) => {
  if (!active) return null
  return children
}

const ITEMS = {
  Ficha: DatosProfesor,
  Horarios: TeacherSchedule,
  Clases: ClasesProfesor
}

const FichaProfesor = () => {
  const [tab, setTab] = useState(0)

  const handleChange = (_e, newValue) => setTab(newValue)

  return (
    <div className='FichaProfesor'>
      <AppBar color='secondary' position='static'>
        <Tabs
          value={tab}
          onChange={handleChange}
          variant='fullWidth'
          indicatorColor='primary'
        >
          {Object.keys(ITEMS)
            .filter(
              label =>
                !process.env.REACT_APP_HIDE_TEACHER_TAB_ITEMS?.split(
                  ','
                ).includes(label)
            )
            .map((label, i) => (
              <Tab key={i} label={label} />
            ))}
        </Tabs>
      </AppBar>
      {Object.entries(ITEMS)
        .filter(
          ([label]) =>
            !process.env.REACT_APP_HIDE_TEACHER_TAB_ITEMS?.split(',').includes(
              label
            )
        )
        .map(([label, Component], i) => {
          return (
            <TabPanel key={i} active={tab === i}>
              <Component />
            </TabPanel>
          )
        })}
    </div>
  )
}

export default FichaProfesor
