import React from 'react'
import { KeyboardDatePicker } from '@material-ui/pickers'

const DateSelector = ({ value, onChange, ...rest }) => (
  <KeyboardDatePicker
    disableToolbar
    variant='inline'
    format='dd/MM/yyyy'
    margin='normal'
    label='Fecha'
    value={value}
    onChange={onChange}
    {...rest}
  />
)

export default DateSelector
