//@ts-check
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  TextField
} from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import React, { useCallback } from 'react'
import LoadingButton from '../../../elements/LoadingButton/LoadingButton'
import useFormManager from '../../../hooks/common/useFormManager'
import './BillingData.css'

const REQUIRED_FIELDS = ['name', 'email', 'dni', 'dateOfBirth']
function BillingForm({ data = {}, isLoading = false, onSubmit }) {
  const {
    handleFormChange,
    handleDateChange,
    getErrorPropsByRequiredField,
    hasPendingRequiredFields,
    form
  } = useFormManager(REQUIRED_FIELDS, data)
  const handleSubmit = useCallback(() => {
    onSubmit(form)
  }, [form, onSubmit])

  return (
    <Card>
      <CardContent className='main_card_content_student'>
        <Grid
          container
          justifyContent='center'
          alignItems='center'
          style={{ width: '70%', margin: '0 auto' }}
          spacing={2}
        >
          <Grid style={{ width: '100%' }} item>
            <Card variant='outlined'>
              <CardHeader title='Datos de facturación' />
              <CardContent>
                <TextField
                  name='name'
                  label='Nombre'
                  value={form.name || ''}
                  onChange={handleFormChange}
                  fullWidth
                  {...getErrorPropsByRequiredField('name')}
                />
                <TextField
                  name='email'
                  margin='normal'
                  label='Correo electrónico'
                  value={form.email || ''}
                  onChange={handleFormChange}
                  fullWidth
                  {...getErrorPropsByRequiredField('email')}
                />
                <div className='inner_grid'>
                  <TextField
                    name='dni'
                    margin='normal'
                    label='DNI'
                    value={form.dni || ''}
                    onChange={handleFormChange}
                    {...getErrorPropsByRequiredField('dni')}
                  />

                  <KeyboardDatePicker
                    label='Fecha de nacimiento'
                    margin='normal'
                    format='dd/MM/yyyy'
                    value={form.dateOfBirth || null}
                    onChange={value => handleDateChange('dateOfBirth', value)}
                    {...getErrorPropsByRequiredField('dateOfBirth')}
                  />
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className='student_card_action'>
        <LoadingButton
          disabled={hasPendingRequiredFields}
          label='Guardar'
          isLoading={isLoading}
          onClick={handleSubmit}
          startIcon={<i className='material-icons'>save</i>}
        />
      </CardActions>
    </Card>
  )
}

export default BillingForm
