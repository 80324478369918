import React from 'react'
import { Button } from '@material-ui/core'
import './Informacion.css'

const VersionDetails = ({ title, features }) => (
  <>
    <h3 className='Informacion-novedades-subtitle'>{title}</h3>
    <ul className='Informacion-novedades-list'>
      {features.map((feature, i) => (
        <li key={i}>{feature}</li>
      ))}
    </ul>
  </>
)

export default () => {
  return (
    <div className='Informacion'>
      <p className='Informacion-descripcion'>
        Estás conectado a <strong>mundoestudiante</strong> versión{' '}
        <strong>1.1.2</strong>, pulsando el botón de recargar, actualizarás a la
        versión más reciente del portal online.
      </p>
      <Button
        className='Informacion-button'
        onClick={() => window.location.reload(true)}
        variant='contained'
        color='primary'
        size='large'
        startIcon={<i className='material-icons'>refresh</i>}
      >
        Recargar
      </Button>
      <div className='Informacion-novedades'>
        <h2 className='Informacion-novedades-title'>Novedades</h2>
        <VersionDetails
          title='Versión 1.1.2'
          features={[
            'Hemos cambiado el logo de la plataforma a mundoestudiante victoria',
            'Cuando estás en clase con un profesor puedes dibujar también sobre la pizarra y el profesor también verá dicho trazado'
          ]}
        />
        <VersionDetails
          title='Versión 1.1.1'
          features={[
            'Hemos mejorado la conexión de los profesores y el funcionamiento de la escritura en pizarra',
            'Hemos añadido el botón de refrescar (borrando caché) en la barra superior de la app'
          ]}
        />
        <VersionDetails
          title='Versión 1.1.0'
          features={[
            'Hemos añadido esta sección para facilitar información de novedades y cambios',
            'Hemos añadido la posibilidad de descargar el contenido de la pizarra en imagen JPG',
            'Hemos añadido más información de los posibles errores de videollamada relacionados con Safari',
            'Ahora los profesores pueden poner fondos de pizarra (líneas horizontales o una cuadrícula) para ayudarles en las clases'
          ]}
        />
      </div>
    </div>
  )
}
