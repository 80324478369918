//@ts-check
import React, { useCallback, useState } from 'react'
import { not } from 'ramda'
import { makeInvoice } from 'api/subscriptions'
import LoadingButton from '../../elements/LoadingButton/LoadingButton'

function emulateClick(href) {
  let a = document.createElement('a')
  a.href = href
  a.target = '_blank'
  a.click()
  a.remove()
}

function InvoiceButton({ subscription, onSuccess, onError }) {
  const [isLoading, setIsLoading] = useState(false)

  const handleClick = useCallback(() => {
    if (subscription.invoiceUrl) {
      return emulateClick(subscription.invoiceUrl)
    }
    setIsLoading(true)
    makeInvoice({ subscriptionId: subscription.id })
      .then(data => {
        if (!data.invoiceAlreadyIssued) onSuccess()
        emulateClick(data.invoiceUrl)
      })
      .catch(e => {
        console.error('Error making invoice: ', e)
        onError('Ha ocurrido un error, revise los datos del alumno')
      })
      .finally(() => setIsLoading(not))
  }, [onError, onSuccess, subscription])

  return (
    <LoadingButton
      color='primary'
      // @ts-ignore
      label={<i className='material-icons'>receipt</i>}
      onClick={handleClick}
      isLoading={isLoading}
      size='small'
      variant='text'
    />
  )
}

export default InvoiceButton
