//BY NOW UNTIL USE ROLES
export const dashboardOptions = {
  top: {
    Dashboard: {
      to: '/',
      icon: 'home',
      title: 'Dashboard'
    },
    Budgets: {
      title: 'Presupuestos',
      icon: 'library_books',
      to: '/budgets'
    },
    Students: {
      to: '/students',
      icon: 'face',
      title: 'Alumnos'
    },
    Staff: {
      isCollapse: true,
      title: 'Personal',
      icon: 'people',
      to: '/staff',
      options: [
        {
          title: 'Profesores',
          linkTo: '/staff/teachers',
          icon: 'account_circle'
        },
        {
          title: 'Administradores',
          linkTo: '/staff/admins',
          icon: 'admin_panel_settings'
        }
      ]
    },
    Planning: {
      to: '/planning',
      icon: 'touch_app',
      title: 'Planificación'
    },
    Operations: {
      to: '/operations',
      icon: 'account_balance_wallet',
      title: 'Operaciones'
    },
    Ticketing: {
      to: '/ticketing',
      icon: 'contact_mail',
      title: 'Ticketing'
    },
    SpecialRequests: {
      to: '/special-requests',
      icon: 'warning',
      title: 'Peticiones especiales',
      showCount: true
    }
  },
  bottom: {
    Connections: {
      to: '/online/connections',
      icon: 'settings_input_antenna',
      title: 'Conexiones'
    },
    Subjects: {
      to: '/subjects',
      icon: 'list',
      title: 'Asignaturas'
    },
    Schedule: {
      to: '/schedule',
      icon: 'schedule',
      title: 'Horario online'
    }
  }
}
