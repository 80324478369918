import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { getStudentNotebooks } from 'api/students'
import { dateTimeToString, getCurrentMonth } from 'utils/date'
import InformationTable from '../../../components/InformationTable/InformationTable'
import MonthSelector from '../../../elements/MonthSelector/MonthSelector'
import YearSelector from '../../../elements/YearSelector/YearSelector'
import TeacherCell from '../../../components/InformationTable/cells/TeacherCell/TeacherCell'
import CuadernilloCell from '../../../components/InformationTable/cells/CuadernilloCell/CuadernilloCell'
import './CuadernillosAlumno.css'

const CuadernillosAlumno = () => {
  const { id } = useParams()
  const [date, setDate] = useState(getCurrentMonth())
  const [classes, setClasses] = useState([])

  useEffect(() => {
    setClasses([])
    getStudentNotebooks({ studentId: id, ...date })
      .then(data =>
        setClasses(
          data.map(notebookSelection => ({
            ...notebookSelection,
            selectionTime: new Date(notebookSelection.selectionTime)
          }))
        )
      )
      .catch(console.error)
  }, [id, date])

  const handleDateChange = key => e => {
    const value = e.target.value
    setDate(currentDate => ({
      ...currentDate,
      [key]: value
    }))
  }

  return (
    <div className='CuadernillosAlumno'>
      <div className='CuadernillosAlumno-filter'>
        <MonthSelector
          value={date.month}
          onChange={handleDateChange('month')}
        />
        <YearSelector value={date.year} onChange={handleDateChange('year')} />
      </div>
      <InformationTable
        details={[
          {
            title: 'Fecha y hora',
            key: 'selectionTime',
            align: 'left',
            sortDisabled: true
          },
          {
            title: 'Asignatura',
            key: 'subject',
            align: 'left',
            sortDisabled: true
          },
          {
            title: 'Cuadernillo',
            key: 'notebook',
            align: 'left',
            sortDisabled: true
          },
          { title: 'Profesor', key: 'teacher', sortDisabled: true }
        ]}
        data={classes.map(notebookSelection => ({
          _id: notebookSelection._id,
          selectionTime: dateTimeToString(notebookSelection.selectionTime),
          teacher: <TeacherCell teacher={notebookSelection.teacher} />,
          subject: notebookSelection.details
            ? notebookSelection.details.subject
            : '--',
          notebook: <CuadernilloCell cuadernillo={notebookSelection.details} />
        }))}
      />
    </div>
  )
}

export default CuadernillosAlumno
